import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {UserLogin} from '../../routes/administrate/class/class.userLogin';
import {Observable} from 'rxjs';
import {User} from '../../routes/administrate/class/class.user';
import { AngularFirestore } from 'angularfire2/firestore';
import { TokenService } from '../../models/services/token.service';

@Injectable({
  providedIn: "root",
})
export class LoginService {
  urlService = environment.urlServer + "authentication/userLogin";
  urlTest = environment.urlServer + "authentication/test";
  city = '';
  private loggedUser;

  constructor(
    private http: HttpClient,
    private firestore: AngularFirestore,
    private tokenService: TokenService
  ) {}

  userLoginJWT(usrLogin: UserLogin): Observable<User> {
    return this.http
        .post<User>(this.urlService, usrLogin);
  }

  get_test(): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.urlTest, { observe: "response" });
  }

  saveLoginRecord(date: Date, ip, location, device, type:string) {
    
    this.loggedUser = this.tokenService.USER;
    this.city = this.loggedUser.selectedCompany.city.name;
    const companyName = this.loggedUser.selectedCompany.companyName;
    const idCompany = this.loggedUser.selectedCompany.id;
    // date.toLocaleString
    // date.toLocaleDateString
    // date.toLocaleTimeString
    const collectionBitacora = this.firestore
      .collection(this.city)
      .doc("Bitacora")
      .collection('fechas');
      // .doc(date.getTime().toString());
    const data = {
      idcompany: idCompany,
      namecompany: this.loggedUser.selectedCompany.companyName,
      date: date,
      ip: ip,
      user: location,
      device: device,
      type: type
    };
    collectionBitacora
      .add(data)
      .then((docRef) => {
        console.log("Registro guardado");
      })
      .catch((error) => {
        console.error("Error en login:", error);
      });
  }
}
