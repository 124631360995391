import {Component, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material';
import {HomeService} from '../../services/home/home.service';
import {NotshippedService} from '../../services/notification/notshipped.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-notshippedentry',
  templateUrl: './notshippedentry.component.html',
  styleUrls: ['./notshippedentry.component.scss']
})
export class NotshippedentryComponent implements OnInit {

  /**
   * Variables para la tabla de material desing
   * Variables for the material design table
   *
   * @private
   * @memberof NotshippedentryComponent
   */
  private displayedColumns = new Array();
  private dataSource;

  /**
   * Paginador y ordenar la tabla de material desing
   * Paginate and sort the material design table
   *
   * @type {MatPaginator}
   * @memberof NotshippedentryComponent
   */
  @ViewChild(MatPaginator, {}) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  /**
   * Contador de pasajeros no embarcados en entrada
   * Counter of passengers not embarked at the entrance
   *
   * @memberof NotshippedentryComponent
   */
  @Output() public count = new EventEmitter<number>();

  /**
   * Variable donde se almacena la data de los pasajeros no embarcados
   * Variable where the data of non-embarked passengers is stored
   *
   * @private
   * @memberof NotshippedentryComponent
   */
  private datatable = new Array();

  constructor(
      private servicehome: HomeService,
      private servicenotshipped: NotshippedService
  ) {
  }

  ngOnInit() {
    this.get_data_routes(this.get_date_current());
  }

  /**
   * Muestra la data, pagina y ordena en la tabla de material desing
   * Display the data, page and order in the desing material table
   *
   * @param {*} data
   * @memberof NotshippedentryComponent
   */
  load_data_table_pagination(data) {
    //console.log(data);
    this.displayedColumns = ['nameroute', 'namepassenger'];
    this.dataSource = new MatTableDataSource<Notshippedentry>(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  /**
   * Obtiene la data de las rutas del colegio y consulta una por una a firebase para obtener los pasajeros no embarcados de las mismas
   * Obtain the data of the routes of the school and consult one by one to firebase to obtain the passengers not embarked from them
   *
   * @memberof HomeComponent
   */
  get_data_routes(date: string) {

    /* 
      * Limpia el arreglo de los pasajeros
      * Clean the passenger arrangement
    */
    this.datatable = [];
    this.count.emit(this.datatable.length);
    this.load_data_table_pagination(this.datatable);

    document.getElementById('cover').style.display = 'block';

    /* 
      * Obtiene las rutas del colegio desde el back-end
      * Get the school routes from the backend
    */
    this.servicehome.get_data_routes_by_idcompany().subscribe(
        response => {
          // console.log(response.body.result.length);
          var counter = 1;
          if (response.body.statusCode == 200) {
            // console.log(response.body);
            for (let i = 0; i < response.body.result.length; i++) {
              for (let j = 0; j < response.body.result[i].pathDTOS.length; j++) {
                if (response.body.result[i].pathDTOS[j].tipoPath == 'Entrada') {
                  var path = response.body.result[i].pathDTOS[j].fbNombre;
                  /*
              * Consulta a firebase los registros con la fecha seleccionada
              * Consult firebase records with the selected date
            */
                  this.servicenotshipped.get_data_date(path, date).subscribe(
                      respuesta => {
                        // console.log(respuesta);
                        for (let y = 0; y < respuesta.length; y++) {
                          var nombreruta = response.body.result[i].nombreRuta;
                          // var pathaux = (response.body.result[i].pathDTOS[0].tipoPath == 'Entrada') ? response.body.result[i].pathDTOS[0].fbNombre : response.body.result[i].pathDTOS[1].fbNombre;
                          if (response.body.result[i].pathDTOS[j].tipoPath == 'Entrada') {
                            var pathaux = response.body.result[i].pathDTOS[j].fbNombre;
                            /*
                              * Consulta a firebase los pasajeros no embarcados deacuerdo a la fecha seleccionada
                              * Consult firebase passengers not embarked according to the selected date
                            */
                            this.servicenotshipped.get_passengers_not_shipped(pathaux, respuesta[y].date).subscribe(
                                response => {
                                  // console.log(response.length);
                                  for (let o = 0; o < response.length; o++) {
                                    if (this.get_validation_repeat_passenger(this.get_time(response[o].payload.doc.data().time.toDate()), this.get_date(response[o].payload.doc.data().time.toDate()), response[o].payload.doc.data().id_passenger) == true) {
                                      // console.log(" USA ESTRE SERVICO PARA TARER LOS NO EMBARCADOS entrada");
                                      // console.log(counter,' ',response[o].payload.doc.data().name);
                                      this.datatable.push({
                                        idpassenger: response[o].payload.doc.data().id_passenger,
                                        namepassenger: response[o].payload.doc.data().name,
                                        nameroute: nombreruta,
                                        date: this.get_date(response[o].payload.doc.data().time.toDate()),
                                        time: this.get_time(response[o].payload.doc.data().time.toDate())
                                      });
                                      counter = counter + 1;
                                      this.count.emit(this.datatable.length);
                                      this.load_data_table_pagination(this.datatable);
                                    }

                                  }
                                }
                            );
                          }

                        }
                      }
                  );
                  // console.log(path);
                }

              }
              var path = (response.body.result[i].pathDTOS[0].tipoPath == 'Entrada') ? response.body.result[i].pathDTOS[0].fbNombre : response.body.result[i].pathDTOS[1].fbNombre;

            }
          }

          document.getElementById('cover').style.display = 'none';

        },
        error => {
          document.getElementById('cover').style.display = 'none';
        }
    );

  }

  /**
   * Genera el archivo de excel con la informacion de la tabla
   * Generate the Excel file with the information in the table
   *
   * @memberof NotshippedentryComponent
   */
  export_excel() {
    var data_excel = new Array();
    for (let i = 0; i < this.datatable.length; i++) {
      var data_object = {
        ruta: this.datatable[i].nameroute,
        pasajero: this.datatable[i].namepassenger
      };

      data_excel.push(data_object);
    }

    this.exportAsExcelFile(data_excel, 'NoEmbarcados_Entrada_' + this.get_date_current());
  }

  /**
   * Filtro para el buscador de la tabla
   * Table finder filter
   *
   * @param {string} filterValue
   * @memberof ActivestudentsComponent
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Obtiene la hora deacuerdo a la fecha enviada como parámetro
   * Get the time according to the date sent as a parameter
   *
   * @param {Date} date
   * @returns
   * @memberof NotshippedentryComponent
   */
  get_time(date: Date) {
    var hours = date.getHours();
    var min = date.getMinutes();
    var sec = date.getSeconds();

    return this.add_zero_date(hours) + ':' + this.add_zero_date(min) + ':' + this.add_zero_date(sec);
  }

  /**
   * Obtiene la fecha deacuerdo a la fecha enviada como parámetro
   * Get the date according to the date sent as a parameter
   *
   * @param {Date} date
   * @returns
   * @memberof NotshippedentryComponent
   */
  get_date(date: Date) {
    var day = date.getDate();
    var mounth = date.getMonth();
    var year = date.getFullYear();

    return year + '-' + this.add_zero_date(mounth) + '-' + this.add_zero_date(day);
  }

  /**
   * Agrega un cero a cualquier elemento de una fecha o hora
   * Add a zero to any element of a date or time
   *
   * @param {*} data
   * @returns
   * @memberof NotshippedentryComponent
   */
  add_zero_date(data) {
    return (data < 10) ? '0' + data.toString() : data;
  }

  /**
   * Valida que no se repita los pasajeros
   * Validates that passengers are not repeated
   *
   * @param {*} time
   * @param {*} date
   * @param {*} id_passenger
   * @returns
   * @memberof NotshippedentryComponent
   */
  get_validation_repeat_passenger(time, date, id_passenger) {
    if (this.datatable.length > 0) {
      for (let i = 0; i < this.datatable.length; i++) {
        if ((this.datatable[i].idpassenger == id_passenger) && (this.datatable[i].date == date) && (this.datatable[i].time == time)) {
          return false;
        }
      }
      return true;
    } else {
      return true;
    }
  }

  /**
   * Genera el archivo de excel con la data proporcionada por parametro
   * Generate the Excel file with the data provided by parameter
   *
   * @param {any[]} json
   * @param {string} excelFileName
   * @memberof NotshippedentryComponent
   */
  public exportAsExcelFile(json: any[], excelFileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    //console.log('worksheet',worksheet);
    const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  /**
   * Obtiene la fecha actual en formato dia-mes-año
   * Get the current date in day-month-year format
   *
   * @returns
   * @memberof NotshippedentryComponent
   */
  get_date_current() {

    var date = new Date();
    var year = date.getUTCFullYear();
    var mounth = date.getUTCMonth() + 1;
    var day = date.getUTCDate();

    return this.add_zero_date(day) + '-' + this.add_zero_date(mounth) + '-' + year;
  }

}

export interface Notshippedentry {
  nameroute: string;
  namepassenger: string;
  time: string;
}
