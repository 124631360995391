import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {catchError, retry, timeout} from 'rxjs/operators';
import {environment, timeout_services} from '../../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {AcademicPeriod, CurrentAcademicPeriodCompany} from '../../routes/administrate/class/class.academicperiod';

@Injectable({
  providedIn: 'root'
})
export class AcademicPeriodService {

  // save new platform period
  postNewPlatformURL = `${environment.urlServer}academicPeriod/save`;
  // get all period
  getAllURL = `${environment.urlServer}academicPeriod/getAll`;
  // compare the active academic period of each UE in every city
  getAllURLByCity = `${environment.urlServer}academicPeriodCompany/getAll`;
  // update high school period
  updateUEPeriodURL = `${environment.urlMicroServices}/adminService/academicPeriodCompanyController/updateToCurrentPeriodAcademicCompany`;
  // updateUEPeriodURL = `${environment.urlServer}academicPeriodCompany/save`;
  // get current academic period, this is used to block a UE
  getCurrentAcademicPeriodURL = `${environment.urlServer}authentication/getCurrentAcademicPeriodStatus`;
  // get current academic period, this is used to block a UE
  enableCurrentAcademicPeriodURL = `${environment.urlServer}academicPeriodCompany/enableCurrentAcademicPeriod`;

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: '',
    Accept: 'application/json'
  });

  constructor(private http: HttpClient) {
  }

  /**
   * get all academic periods from db
   * */
  getAllAcademicPeriod(): Observable<HttpResponse<AcademicPeriod[]>> {
    return this.http.get<AcademicPeriod[]>(this.getAllURL, {observe: 'response'}).pipe(
        timeout(timeout_services),
        retry(3),
        catchError(this.handleError)
    );
  }

  /**
   * compare the active academic period of each UE in every city
   * @param city
   * @param period
   * */
  getUEListPeriodStatusByCity(city, period) {
    return this.http.get<AcademicPeriod[]>(`${this.getAllURLByCity}/${city}/${period}`, {observe: 'response'}).pipe(
        timeout(timeout_services),
        retry(3),
        catchError(this.handleError)
    );
  }

  /**
   * save platform academic period
   * @param objectSave
   * */
  savePlatformAcademicPeriod(objectSave: AcademicPeriod) {
    return this.http.post(this.postNewPlatformURL, objectSave).pipe(
        timeout(timeout_services),
        retry(3),
        catchError(this.handleError)
    );
  }

  /**
   * update academic period of selected UE
   * */
  updateUEAcademicPeriod(updateUEPeriod) {
    console.log('periodo  ',updateUEPeriod);
    return this.http.post(this.updateUEPeriodURL, updateUEPeriod).pipe(
        timeout(timeout_services),
        retry(1),
        catchError(this.handleError)
    );
  }

  /**
   * Get all academic periods from db
   * */
  getCurrentPeriodAcademicStatus(id) {
    return new Promise((resolve) => {
      const apiURL = `${this.getCurrentAcademicPeriodURL}/${id}`;
      this.http.get(apiURL)
          .pipe(
              timeout(timeout_services),
              retry(3),
              catchError(this.handleError),
          )
          .toPromise()
          .then(
              res => { // Success
                resolve(res);
              }
          );
    });
  }

  /**
   * enable current academic period
   * */
  enableCurrentAcademicPeriodStatus(actualAcademicPeriod: CurrentAcademicPeriodCompany) {
    console.log('envio ', actualAcademicPeriod);
    return this.http.put(this.enableCurrentAcademicPeriodURL, actualAcademicPeriod).pipe(
        timeout(timeout_services),
        retry(3),
        catchError(this.handleError)
    );
  }

  /**
   * handle error of http response
   * @param error
   * */
  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
    } else {
    }
    return throwError(
        'Something bad happened; please try again later.');
  }
}
