import {Component, OnInit, OnDestroy} from '@angular/core';

declare var $: any;

import {SettingsService} from '../../core/settings/settings.service';
import {ThemesService} from '../../core/themes/themes.service';
import {TranslatorService} from '../../core/translator/translator.service';
import {Company} from '../../routes/administrate/class/class.company';
import {AuthService} from '../../services/auth/auth.service';
import {Menu} from '../../routes/administrate/class/class.menu';
import {User} from '../../routes/administrate/class/class.user';
import {AcademicPeriodService} from '../../services/academic-period/academic-period.service';
import {menuBlocked, menuDisabled} from '../../routes/menu';
import {TokenService} from '../../models/services/token.service';

const swal = require('sweetalert');

@Component({
  selector: 'app-offsidebar',
  templateUrl: './offsidebar.component.html',
  styleUrls: ['./offsidebar.component.scss']
})
export class OffsidebarComponent implements OnInit, OnDestroy {
  currentTheme: any;
  selectedLanguage: string;
  clickEvent = 'click.offsidebar';
  $doc: any = null;
  companies: Company[];
  user;

  constructor(
      public settings: SettingsService,
      public themes: ThemesService,
      public translator: TranslatorService,
      private authService: AuthService,
      private _aS: AcademicPeriodService,
      private token: TokenService,
      private _authService: TokenService
  ) {
    this.currentTheme = themes.getDefaultTheme();
    this.selectedLanguage = this.getLangs()[0].code;
  }

  ngOnInit() {
    this.anyClickClose();
    this.companies = this.token.USER.companies; // get companies for list in offsidebar.html
    this.user = this.token.USER; // get user data of logged
  }

  setTheme() {
    this.themes.setTheme(this.currentTheme);
  }

  getLangs() {
    return this.translator.getAvailableLanguages();
  }

  setLang(value) {
    this.translator.useLanguage(value);
  }

  anyClickClose() {
    this.$doc = $(document).on(this.clickEvent, e => {
      if (!$(e.target).parents('.offsidebar').length) {
        this.settings.setLayoutSetting('offsidebarOpen', false);
      }
    });
  }

  ngOnDestroy() {
    if (this.$doc) {
      this.$doc.off(this.clickEvent);
    }
  }

  // -------------------- CHANGE COMPANY AND LOAD SERVICES ---------------------
  fun_change_company_selected(company) {
    if (this.user.selectedCompany.id !== company.id) {
      this.fun_set_selected_company(company);
    } else {
      swal(
          'Colegio seleccionado',
          'Usted ya se encuentra en ese colegio',
          'info'
      );
    }
  }

  // ------------- SET A NEW SELECTED COMPANY ----------------
  fun_set_selected_company(company) {
    this.user.selectedCompany = company;
    // sessionStorage.setItem('userInformation', JSON.stringify(this.user));
    this.authService
        .updateSelectedCompanyByIDandIDCompany(this.user.id, company.id)
        .subscribe(
            resultMenu => {
              // this._authService.clearStorage();
              // console.log(resultMenu);
              this._authService.saveUserToken(resultMenu.mensaje.toString());
              this._authService.saveUserData(resultMenu.mensaje.toString());
              this.get_menu_submenu(this.token.USER.selectedCompany.rol.id);
            },
            // to manage error
            error => {
              console.error('Fallaron las credenciales de ese colegio');
            },
            // on complete function manage a callback
            () => {
            }
        );
  }

  // ----------------- GET MENU AND SUBMENU FROM BACKEND -------------------------------
  async get_menu_submenu(idRol: number) {
    const resp = await this._aS.getCurrentPeriodAcademicStatus(this.user.selectedCompany.id);
    let menu: Menu[] = [];
    this.authService.getrolMenuSubmenu(idRol).subscribe(resultMenu => {
          if (resultMenu.body.length) {
            // compruebo si es administrador
            if (this.user.generalRol === 1) {
              menu = resultMenu.body;
            } else {
              // si tengo datos de un periodo anterior
              if (resp['statusCode'] === '200') {
                sessionStorage.setItem('academic_period', JSON.stringify(resp['result'][0]));
                // si se encuentra activo el periodo, muestro el menu correcto
                if (resp['result'][0]['academicPeriodStatus']) {
                  menu = resultMenu.body;
                } else {
                  menu = menuBlocked;
                }
              } else {
                sessionStorage.setItem('academic_period', JSON.stringify({}));
                menu = menuDisabled;
              }
            }
            sessionStorage.setItem('menu_submn', JSON.stringify(menu));
            location.assign(menu[0].link.substring(1, menu[0].link.length));
          }
        },
        // to manage error
        error => {
          console.error('Fallaron las credenciales de ese colegio');
        },
        // on complete function manage a callback
        () => {
        }
    );
  }

  userLogout(event) {
    //  console.log("out");
    setTimeout(() => {
      this.token.logOut();
      location.assign('login');
    }, 0);
  }
}
