import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {User} from "../../../routes/administrate/class/class.user";
import {MatPaginator} from "@angular/material/paginator";
import {NewsService} from "../../../services/notification/news.service";
import {MatTableDataSource} from "@angular/material/table";
import {Newadmin} from "../../class/class.newadmin";
import {TokenService} from '../../../models/services/token.service';
import { ConvertListVoucher, Voucher } from '../../../routes/administrate/class/voucher/class.voucher';
import { AcademicSession } from '../../class/class.academic.session';
import { AcademicSessionService } from '../../../models/services/academic-session.service';
import { PointsService } from '../../../services/points/points.service';

@Component({
  selector: 'app-notificationsadmin',
  templateUrl: './notificationsadmin.component.html',
  styleUrls: ['./notificationsadmin.component.scss']
})
export class NotificationsadminComponent implements OnInit {

  /**
   * Variables de la tabla de material desing
   * Variables in the material design table
   *
   * @private
   * @memberof NewsadminComponent
   */
  private displayedColumns = new Array();
  private dataSource;
  totalNot1: number = 0;
  totalNot2: number = 0;

  /**
   * Variable para acceder a la informacion del usuario logeado
   * Variable to access logged-in user information
   *
   * @private
   * @type {User}
   * @memberof NewsadminComponent
   */
  private userSession;
  academicSession:AcademicSession;
  
  /**
   * Contador de novedades enviadas al administrador
   * News counter sent to the administrator
   *
   * @memberof NewsadminComponent
   */
  @Output() public total = new EventEmitter<number>();
  @Output() public totalVoucher = new EventEmitter<number>();

  /**
   * Paginador de la tabla de material desing
   * Material designation table pager
   *
   * @type {MatPaginator}
   * @memberof NewsadminComponent
   */
  @ViewChild(MatPaginator, {}) paginator: MatPaginator;
  
 
  constructor(
    private newService: NewsService,
    private pointservice: PointsService,
    private token: TokenService,
    private academicSessionServices: AcademicSessionService
  ) {}

  ngOnInit() {
    this.userSession = this.token.USER;
    this.academicSession = this.academicSessionServices.getAcademicPeriod();
    this.loaddatafirebase();
    this.loadDataFirebase2();
  }

  loaddatafirebase() {
    const nameCompany = this.userSession.selectedCompany.companyName;
    const idCompany: number = this.userSession.selectedCompany.id;
    let academicPeriod: string = "";

    if (this.academicSession != null) {
      academicPeriod = this.academicSession.academicPeriodDescription;
    }

    // this.newService.get_data_noti_admin(nameCompany).subscribe(
    //   response => {
    //     console.log("resno", response);
    //     this.totalNot1 = response.length;
    //       this.total.emit(response.length);
    //     },
    //     error => {
    //     }
    // );

    this.newService.getCompanyActiveVoucher(idCompany).subscribe(
      (resp: any) => {
        if (resp != undefined) {
          if (resp.status) {
            this.newService
              .getCountVouchersPending(idCompany, academicPeriod)
              .subscribe(
                (resp: any) => {
                  let count = 0;
                  let listVoucher: Voucher[] = [];
                  listVoucher = ConvertListVoucher.toVoucher(
                    JSON.stringify(resp)
                  );
                  listVoucher.forEach((v) => {
                    if (!v.viewed) {
                      count += 1;
                    }
                  });
                  this.totalVoucher.emit(count);
                },
                (error) => {
                  console.log(error);
                }
              );
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  loadDataFirebase2 = async () => {
    let notificationsGlobalTotal: any[] = [];

    let listNotifyAdmin: any[] = [];
    let listNotifyChangeSchool: any[] = [];
    let listNotifyDeletePassenger: any[] = [];
    let listNotifyErrorCompany: any[] = [];
    let listNotifyRequestPoint: any[] = [];
    let listNoAssistance: any[] = [];
    let listExtracurriculares: any[] = [];
    let listNotificationsAdmin: any[] = [];

    const nameCompany = this.userSession.selectedCompany.companyName;

    this.newService
      .get_data_noti_admin(nameCompany)
      .subscribe((respNotifyAdmin) => {
        notificationsGlobalTotal = [];
        listNotifyAdmin = [];
        listNotifyAdmin = respNotifyAdmin;

        notificationsGlobalTotal = [
          ...listNotifyAdmin,
          ...listNotifyChangeSchool,
          ...listNotifyDeletePassenger,
          ...listNotifyErrorCompany,
          ...listNotifyRequestPoint,
          ...listNoAssistance,
          ...listExtracurriculares,
          ...listNotificationsAdmin,
        ];
        this.getTotalnoti(notificationsGlobalTotal);
      });

    this.newService
      .get_data_change_school(nameCompany)
      .subscribe((respNotifyChangeSchool) => {
        notificationsGlobalTotal = [];
        listNotifyChangeSchool = [];

        listNotifyChangeSchool = respNotifyChangeSchool;
        notificationsGlobalTotal = [
          ...listNotifyAdmin,
          ...listNotifyChangeSchool,
          ...listNotifyDeletePassenger,
          ...listNotifyErrorCompany,
          ...listNotifyRequestPoint,
          ...listNoAssistance,
          ...listExtracurriculares,
          ...listNotificationsAdmin,
        ];
        this.getTotalnoti(notificationsGlobalTotal);
      });

    this.newService
      .get_data_delete_passenger(nameCompany)
      .subscribe((respNotifyDeletePassenger) => {
        notificationsGlobalTotal = [];
        listNotifyDeletePassenger = [];

        listNotifyDeletePassenger = respNotifyDeletePassenger;
        notificationsGlobalTotal = [
          ...listNotifyAdmin,
          ...listNotifyChangeSchool,
          ...listNotifyDeletePassenger,
          ...listNotifyErrorCompany,
          ...listNotifyRequestPoint,
          ...listNoAssistance,
          ...listExtracurriculares,
          ...listNotificationsAdmin,
        ];
        this.getTotalnoti(notificationsGlobalTotal);
      });

    this.newService
      .getDataErrorCompany(this.userSession.selectedCompany.id)
      .subscribe((respDataErrorCompany) => {
        notificationsGlobalTotal = [];
        listNotifyErrorCompany = [];

        listNotifyErrorCompany = respDataErrorCompany.map((data) => {
          let listPhoneAdvisor: string[] = [];

          let typeError = "Error User";
          if (data.typeNotification === "assignation_passenger") {
            typeError = "Asignar Alumno";
          }
          if (data.typeNotification === "company_error_route") {
            typeError = "Problema Ruta";
          }
          if (data.typeNotification === "company_error_driver") {
            typeError = "Problema Conductor";
          }
          if (data.typeNotification === "company_error_vehicle") {
            typeError = "Problema Vehiculo";
          }
          if (data.typeNotification === "company_error_others") {
            typeError = "Problema Otros";
          }

          if (typeof data.phoneAdvisor == "string") {
            listPhoneAdvisor = [data.phoneAdvisor.toString().trim()];
          }
          if (typeof data.phoneAdvisor == "object") {
            listPhoneAdvisor = [...data.phoneAdvisor];
          }
          return {
            ...data,
            dia: data.date,
            nombreConductor: data.nameAdvisor,
            nombreEstudiante: data.passengerName,
            tipo: typeError,
            leido: data.read,
            detail: data.message,
            listPhoneAdvisor: listPhoneAdvisor,
          };
        });
        notificationsGlobalTotal = [
          ...listNotifyAdmin,
          ...listNotifyChangeSchool,
          ...listNotifyDeletePassenger,
          ...listNotifyErrorCompany,
          ...listNotifyRequestPoint,
          ...listNoAssistance,
          ...listExtracurriculares,
          ...listNotificationsAdmin,
        ];
        this.getTotalnoti(notificationsGlobalTotal);
      });

    this.pointservice
      .get_data_request_points_by_idcompany(this.userSession.selectedCompany.id)
      .subscribe((respRequestPoint) => {
        notificationsGlobalTotal = [];
        listNotifyRequestPoint = [];

        if (respRequestPoint.body.statusCode == 200) {
          let jsonResp: any[] = respRequestPoint.body.result;
          let jsonResult = jsonResp.map((e) => {
            return {
              leido: false,
              ...e,
            };
          });
          listNotifyRequestPoint = jsonResult;
        }
        notificationsGlobalTotal = [
          ...listNotifyAdmin,
          ...listNotifyChangeSchool,
          ...listNotifyDeletePassenger,
          ...listNotifyErrorCompany,
          ...listNotifyRequestPoint,
          ...listNoAssistance,
          ...listExtracurriculares,
          ...listNotificationsAdmin,
        ];
        this.getTotalnoti(notificationsGlobalTotal);
      });

    // Notificaciones de no assitencia
    this.newService
      .getDataNoAssistance(this.userSession.selectedCompany.companyName)
      .subscribe((respNotifyAbsencePassenger) => {
        notificationsGlobalTotal = [];
        listNoAssistance = [];
        listNoAssistance = respNotifyAbsencePassenger;
        notificationsGlobalTotal = [
          ...listNotifyAdmin,
          ...listNotifyChangeSchool,
          ...listNotifyDeletePassenger,
          ...listNotifyErrorCompany,
          ...listNotifyRequestPoint,
          ...listNoAssistance,
          ...listExtracurriculares,
          ...listNotificationsAdmin,
        ];
        this.getTotalnoti(notificationsGlobalTotal);
      });

    // Notificaciones de solicitud para extracurriculares
    this.newService
      .getDataExtracurricular(this.userSession.selectedCompany.companyName)
      .subscribe((respNotifyExtracurricular) => {
        notificationsGlobalTotal = [];
        listExtracurriculares = [];

        if (respNotifyExtracurricular.length > 0) {
          let jsonResp: any[] = respNotifyExtracurricular;
          let jsonResult = jsonResp.map((e) => {
            return {
              leido: false,
              ...e,
            };
          });
          listExtracurriculares = jsonResult;
        }

        // listExtracurriculares = respNotifyExtracurricular;
        notificationsGlobalTotal = [
          ...listNotifyAdmin,
          ...listNotifyChangeSchool,
          ...listNotifyDeletePassenger,
          ...listNotifyErrorCompany,
          ...listNotifyRequestPoint,
          ...listNoAssistance,
          ...listExtracurriculares,
          ...listNotificationsAdmin,
        ];
        this.getTotalnoti(notificationsGlobalTotal);
      });

    this.newService.getListNotifyAdminSupport().subscribe((response) => {
      response.forEach((element) => {
        element["leido"] = element["read"];
        element["dia"] = element["date"];
        element["nombreRepresentante"] = element["nameAdvisor"];
        element["nombreEstudiante"] = element["passengerName"];
        if (element["typeNotification"] == "assignation_passenger") {
          element["tipo"] = "Asignacion Pasajero";
        } else {
          element["tipo"] = "Error en Ruta";
        }
      });
      notificationsGlobalTotal = [];
      listNoAssistance = [];
      listNotificationsAdmin = response;
      notificationsGlobalTotal = [
        ...listNotifyAdmin,
        ...listNotifyChangeSchool,
        ...listNotifyDeletePassenger,
        ...listNotifyErrorCompany,
        ...listNotifyRequestPoint,
        ...listNoAssistance,
        ...listExtracurriculares,
        ...listNotificationsAdmin,
      ];
      this.getTotalnoti(notificationsGlobalTotal);
    });
  };

  getTotalnoti(data) {
    const noleido = data.filter((resp) => resp["leido"] == false);
    this.totalNot2 = noleido.length;
    this.total.emit(this.totalNot1 + this.totalNot2);
  }
}
