import {Component, OnDestroy, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {fromEvent, Observable} from 'rxjs';
import {Subscription} from 'rxjs/Subscription';
import {SendEmailService} from "../../services/sendEmail/send-email.service";
const swal = require("sweetalert");

declare var $: any;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

export class IndexComponent implements OnInit, OnDestroy {

  lat = -2.892118;
  lng = -79.022254;
  year = new Date().getFullYear();
  contactForm: FormGroup;
  submitted = false;
  siteKey = environment.apikey;
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  constructor(private formBuilder: FormBuilder,
              private emailService: SendEmailService) {
  }

  public ngOnInit() {
    window.location.href = "https://mibuseta.com/login";

    // this.resizeObservable$ = fromEvent(window, 'resize');
    // this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
    //   this.isMobileView();
    // })
    // this.contactForm = this.formBuilder.group({
    //   name: ['', Validators.required],
    //   country: ['', Validators.required],
    //   city: ['', Validators.required],
    //   issue: ['', Validators.required],
    //   email: ['', Validators.required],
    //   phone: ['', Validators.required],
    //   message: ['', Validators.required],
    //   // recaptcha: ['', Validators.required]
    // });
    // $('#button1').click(function () {
    //   location.assign('login');
    // });
    // $('#button2').click(function () {
    //   location.assign('https://mibusetapadresweb.web.app/#/');
    // });
    // $('#button3').click(function () {
    //   location.assign('https://mibusetafacturacionweb.web.app/');
    // });



    /*let navWidth = $(window).width();
    if (navWidth < 640) {
      $('#btn-submit').addClass('btn-block');
    } else {
      $('#btn-submit').removeClass('btn-block');
    }*/
    /*$(window).bind('scroll', function () {
      let navHeight = $(window).height() - 200;
      if ($(window).scrollTop() > navHeight) {
        $('.navbar-light').addClass('on');
      } else {
        $('.navbar-light').removeClass('on');
      }
    });*/

    // $('#hover1').hover(function () {
    //   document.getElementById('div3').innerHTML = '';
    //   document.getElementById('div3').innerHTML = '<img src="../assets/img/rastreo.png" id="img10" style="width:80%; margin-top:20vh; margin-bottom:10%" alt="">';
    //
    // });
    // $('#hover2').hover(function () {
    //   document.getElementById('div3').innerHTML = '';
    //   document.getElementById('div3').innerHTML = '<img src="../assets/img/reporte.png" id="img10" style="width:80%; margin-top:20vh; margin-bottom:10%" alt="">';
    //
    // });
    // $('#hover3').hover(function () {
    //   document.getElementById('div3').innerHTML = '';
    //   document.getElementById('div3').innerHTML = '<img src="../assets/img/facturas.png" id="img10" style="width:80%; margin-top:20vh; margin-bottom:10%" alt="">';
    //
    // });
    // $('#hover4').hover(function () {
    //   document.getElementById('div3').innerHTML = '';
    //   document.getElementById('div3').innerHTML = '<img src="../assets/img/recopilacion.png" id="img10" style="width:80%; margin-top:20vh; margin-bottom:10%" alt="">';
    //
    // });
    // $('#hover5').hover(function () {
    //   document.getElementById('div3').innerHTML = '';
    //   document.getElementById('div3').innerHTML = '<img src="../assets/img/rastreotr.png" id="img10" style="width:80%; margin-top:20vh; margin-bottom:10%" alt="">';
    //
    // });
    // $('#hover6').hover(function () {
    //   document.getElementById('div3').innerHTML = '';
    //   document.getElementById('div3').innerHTML = '<img src="../assets/img/Analytics.png" id="img10" style="width:80%; margin-top:20vh; margin-bottom:10%" alt="">';
    //
    // });
    // let sections = $('section')
    //     , nav = $('nav')
    //     , nav_height = nav.outerHeight();
    //
    // $(window).on('scroll', function () {
    //   let cur_pos = $(this).scrollTop();
    //   sections.each(function () {
    //     let top = $(this).offset().top - nav_height,
    //         bottom = top + $(this).outerHeight();
    //     if (cur_pos >= top && cur_pos <= bottom) {
    //       nav.find('a').removeClass('active');
    //       sections.removeClass('active');
    //       $(this).addClass('active');
    //       nav.find('a[href="#' + $(this).attr('id') + '"]').addClass('active');
    //     }
    //   });
    // });
    // let arrayimg = new Array();
    // let arraylog = new Array();
    // arrayimg[0] = 'mock';
    // arrayimg[1] = 'mock1';
    // arrayimg[2] = 'mock2';
    //
    // arraylog[0] = 'logo';
    // arraylog[1] = 'log1';
    // arraylog[2] = 'log2';
    // let contadorimg = 0;
    //
    // onload = function () {
    //   // Cargamos una imagen aleatoria
    //   function girar() {
    //     // console.log('img');
    //     if (contadorimg < arrayimg.length - 1) {
    //       contadorimg = contadorimg + 1;
    //       $('#img1').attr('src', '/../assets/img/' + arrayimg[contadorimg] + '.png');
    //       $('#img2').attr('src', '/../assets/img/' + arraylog[contadorimg] + '.png');
    //     } else {
    //       contadorimg = 0;
    //       $('#img1').attr('src', '/../assets/img/' + arrayimg[contadorimg] + '.png');
    //       $('#img2').attr('src', '/../assets/img/' + arraylog[contadorimg] + '.png');
    //     }
    //   }
    //
    //   // Indicamos que cada 5 segundos cambie la imagen
    //   setInterval(girar, 3000);
    // };
    //
    // $('#ModalVideoPadres').on('hide.bs.modal', (e) => {
    //   let media = $('#videoModalPadres').get(0);
    //   media.pause();
    //   media.currentTime = 0;
    // })
    //
    // $('#ModalVideo').on('hide.bs.modal', (e) => {
    //   let media = $('#videoModal').get(0);
    //   media.pause();
    //   media.currentTime = 0;
    // })
  }

  onSubmit() {
    if (!this.contactForm.valid) {
      alert("Revise la informacion del formulario");
      return;
    }
    swal(
        {
          //popup message
          title: "¿Está seguro de la información proporcionada?",
          text:
              "Verifique la información antes de ser enviada",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#12B300",
          confirmButtonText: "Si, enviar.",
          cancelButtonText: "Cancelar",
          closeOnConfirm: true,
          closeOnCancel: true
        },
        isConfirm => {
          if (isConfirm) {
            this.emailService.sendEmailContact(this.contactForm.value)
                .then(() => {
                  this.contactForm.reset();
                  swal(
                      {
                        //popup message
                        title: "Ok",
                        text:
                            "El mensaje fue enviado, pronto un asesor se comunicará con usted",
                        type: "success",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      });
                })
                .catch(() => {
                  swal(
                      {
                        //popup message
                        title: "Error",
                        text:
                            "Se produjo un error el enviar el mensaje, por favor, intente mas tarde",
                        type: "danger",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      });
                });
          }
        }
    );
  }

  isMobileView() {
    if ($(window).width() < 991) {
      $('#btn-submit').addClass('btn-block');
    } else {
      $('#btn-submit').removeClass('btn-block');
    }
  }

  ngOnDestroy(): void {
    this.resizeSubscription$.unsubscribe();
  }

}
