import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeproduct'
})
export class TypeproductPipe implements PipeTransform {

  /**
   * Transforma segun el id del tipo de producto al nombre que le corresponde
   * Transform according to the product type id to the name that corresponds to it
   *
   * @param {number} value Id del tipo de producto => Product Type Id
   * @returns {string}
   * @memberof TypeproductPipe
   */
  transform(value: number): string {
    if(value == 1){
      return "Completo";
    }

    if(value == 2){
      return "Entrada";
    }

    if(value == 3){
      return "Salida";
    }

    if(value == 4){
      return "Combinado";
    }
  }

}
