import { Injectable } from '@angular/core';
import {AngularFirestoreCollection, AngularFirestore} from '@angular/fire/firestore';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface Email {
  from_email?: string;
  from_name?: string;
  to_email?: string;
  to_name?: string;
  subject?: string;
  message?: string;
}
//DTO Sending Email
export interface SendingEmailDTO {
  usersToSend: string;
  electronicDocumentName: string | null;
  electronicSubject: String | null
  electronicBody: String | null
  electronicVoucherXML: string | null;
  electronicVoucherPDF: string | null;
}


@Injectable({
  providedIn: 'root'
})
export class SendEmailService {
  private EmailCollection: AngularFirestoreCollection<Email>;
  public email: Email;

  constructor(private db: AngularFirestore, private http: HttpClient) {
    this.EmailCollection = this.db.collection<Email>(`emails`);
  }

  sendEmailContact(info: any) {
    this.email = {
      from_email: info.email,
      from_name: info.name,
      to_email: 'info@weconnect.com.ec',
      to_name: 'WE CONNECT',
      subject: info.issue,
      message: info.message
    };
    const collection = this.EmailCollection;
    return collection.add(this.email);
  }

  //Url del Servicio
  private urlEmail = environment.urlMicroServices + '/adminService/emailController/sendEmail';

  sendEmail(emailData: SendingEmailDTO): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.urlEmail, emailData, { observe: 'response' });
  }

}
