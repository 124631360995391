import { Component, OnInit } from "@angular/core";
import swal from 'sweetalert';
import { UserblockService } from "./userblock.service";
import { Subscription } from "rxjs";
import { User } from "../../../routes/administrate/class/class.user";
import { Consortium } from "../../../routes/administrate/class/class.consortium";
import {TokenService} from '../../../models/services/token.service';
import {UserBlockService} from '../../../services/userBlock/user-block.service';
import * as QRCode from 'qrcode';


@Component({
  selector: "app-userblock",
  templateUrl: "./userblock.component.html",
  styleUrls: ["./userblock.component.scss"]
})
export class UserblockComponent implements OnInit {
  user: any;
  userName: string;
  userEmail: string;
  superAdmin: boolean = false;
  ueCode: string;
  showButton: boolean = false;
  userInformation;
  constructor(public userblockService: UserblockService, private token: TokenService, private serviceUserBlock: UserBlockService,) {
    this.userInformation = this.token.USER;
    this.superAdmin = this.token.USER.generalRol !== 0;

    this.user = {
      picture: this.userInformation.consortium ? "assets/img/user/01.jpg" : "assets/img/casaN1.png"
    };
  }
  private _subscriptionBlockService: Subscription;

  ngOnInit() {
    this.get_user_data();
  }

  fun_load_swal(title, text, type) {
    swal({
      title: title,
      text: text,
      type: type,
      showCancelButton: false,
      confirmButtonColor: '#8edbff',
      confirmButtonText: 'Aceptar',
      // cancelButtonText: 'Cancelar',
      closeOnConfirm: true,
      closeOnCancel: true
    }, (isConfirm) => {

    });
  }

  userBlockIsVisible() {
    return this.userblockService.getVisibility();
  }

  //---------- GET DATA FROM SESSION STORAGE FOR LOAD IN HTML -----------------
  get_user_data() {
    this.userName = this.token.USER.name;
    this.userEmail = this.token.USER.email;
    this.ueCode = this.get_base64_code(this.userInformation.selectedCompany.id.toString());

  }

  get_base64_code(uecode: string) {
    let auxString;
    if (uecode.length <= 2) {
      auxString = `0${uecode}`;
    } else {
      auxString = uecode;
    }
    return btoa(auxString);
  }

  ngOnDestroy(): void {
    //  To unsuscribe all the services
    if (this._subscriptionBlockService) {
      this._subscriptionBlockService.unsubscribe();
    }
  }
  // Evento para detectar la acción del mouse
  handleMouseOver() {
    this.showButton = true;
    // Espera 4 segundos para ocultar el boton
    setTimeout(() => {
      this.showButton = false;
    }, 4000);
  }
  // Genera el codigo Qr
  // Genera el codigo Qr
  generateQr() {
    this.serviceUserBlock.get_qr(this.ueCode).subscribe(
        response => {
          const appStoreLink = response.body.shortLink;
          const canvas = document.createElement('canvas');
          QRCode.toCanvas(canvas, appStoreLink, (error) => {
            if (error) {
              console.error('Error al generar el código QR', error);
            } else {
              // Convertir el canvas a imagen PNG
              canvas.toBlob((blob) => {
                // Crear un objeto URL para el archivo
                const url = URL.createObjectURL(blob);

                // Crear un enlace de descarga
                const downloadLink = document.createElement('a');
                downloadLink.href = url;
                downloadLink.download = this.ueCode + '.png';

                // Simular un clic en el enlace de descarga para iniciar la descarga
                downloadLink.click();

                // Liberar el objeto URL
                URL.revokeObjectURL(url);
              }, 'image/png');
            }
          });
        },
        () => {
          this.fun_load_swal('Aviso', 'Ha ocurrido un error con el servidor.', 'error');
          document.getElementById('cover').style.display = 'none';
        }
    );
    // LINK Qr
    // const appStoreLink =  this.serviceUserBlock.get_qr(this.ueCode);
    // // ANDROID
    // // ANDROID

  }
}
