// To parse this data:
//
//   import { Convert, AcademicSession } from "./file";
//
//   const academicSession = Convert.toAcademicSession(json);

export class AcademicSession {
    academicPeriodID:          number;
    academicPeriodStatus:      boolean;
    academicPeriodDescription: string;
    currentAcademicPeriodID:   string;
}

// Converts JSON strings to/from your types
export class ConvertAcademicSession {
    public static toAcademicSession(json: string): AcademicSession {
        return JSON.parse(json);
    }

    public static academicSessionToJson(value: AcademicSession): string {
        return JSON.stringify(value);
    }
}
