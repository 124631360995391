import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NotshippedentryComponent } from '../notshippedentry/notshippedentry.component';
import { NotshippedexitComponent } from '../notshippedexit/notshippedexit.component';

@Component({
  selector: 'app-notshipped',
  templateUrl: './notshipped.component.html',
  styleUrls: ['./notshipped.component.scss']
})
export class NotshippedComponent implements OnInit {

  /**
   * Contadores de pasajeros no embarcados de entrada y salida
   * Counters of passengers not embarked in and out
   *
   * @private
   * @memberof NotshippedComponent
   */
  private count_entry = 0;
  private count_exit = 0;

  /**
   * Variables para mostrar la fecha actual o la seleccionada
   * Variables to display the current or selected date
   *
   * @private
   * @memberof NotshippedComponent
   */
  private date_selected;
  private date_current;

  /**
   * Emite el total de alumnos no embarcados en entrada y salida
   * Issues the total number of students not embarked at entry and exit
   *
   * @memberof NotshippedComponent
   */
  @Output() public total = new EventEmitter<number>();
  
  /**
   * Instancias de los componentes hijos
   * Instances of the child components
   *
   * @type {NotshippedentryComponent}
   * @memberof NotshippedComponent
   */
  @ViewChild(NotshippedentryComponent) notshippedentryComponent: NotshippedentryComponent;
  @ViewChild(NotshippedexitComponent) notshippedexitComponent: NotshippedexitComponent;

  constructor() { }

  ngOnInit() {
    this.date_selected = this.get_date_current_format();
    this.date_current = this.get_date_current_format();
  }

  /**
   * Calcula el total cuando se produce algun cambio en pasajeros no embarcados en entrada
   * Calculate the total when there is any change in passengers not embarked at the entrance
   *
   * @param {*} event
   * @memberof NotshippedComponent
   */
  getcountentry(event){
    this.count_entry = event;
    this.total.emit(this.count_entry + this.count_exit);
  }

  /**
   * Calcula el total cuando se produce algun cambio en pasajeros no embarcados en salida
   * Calculate the total when there is any change in passengers not embarked on departure
   *
   * @param {*} event
   * @memberof NotshippedComponent
   */
  getcountexit(event){
    this.count_exit = event;
    this.total.emit(this.count_entry + this.count_exit);
  }

  /**
   * Obtiene la fecha actual en formato año-mes-dia
   * Get the current date in year-month-day format
   *
   * @returns
   * @memberof NotshippedComponent
   */
  get_date_current_format(){

    var date = new Date();
    var year = date.getUTCFullYear();
    var mounth = date.getUTCMonth()+1;
    var day = date.getUTCDate();

    return year+"-"+this.add_zero_date(mounth)+"-"+this.add_zero_date(day);
  }

  /**
   * Obtiene la fecha actual en formato dia-mes-año
   * Get the current date in day-month-year format
   *
   * @param {*} dateselected
   * @returns
   * @memberof NotshippedComponent
   */
  get_date_current_format_day_mounth_year(dateselected){

    var date = new Date(dateselected);
    var year = date.getUTCFullYear();
    var mounth = date.getUTCMonth()+1;
    var day = date.getUTCDate();

    return this.add_zero_date(day)+"-"+this.add_zero_date(mounth)+"-"+year;
  }

  /**
   * Agrega el cero a cualquier elemento de una fecha que se requiera
   * Add zero to any element of a required date
   *
   * @param {*} data
   * @returns
   * @memberof NotshippedComponent
   */
  add_zero_date(data){
    return (data < 10) ? "0" + data.toString() : data;
  }

  /**
   * Obtiene los pasajeros no embarcados en entrada y salida deacuerdo a la fecha seleccionada
   * Obtain passengers not embarked at entry and exit according to the selected date
   *
   * @memberof NotshippedComponent
   */
  get_data_not_shipped(){
    //console.log(this.get_date_current_format_day_mounth_year(this.date_selected));
    this.notshippedentryComponent.get_data_routes(this.get_date_current_format_day_mounth_year(this.date_selected));
    this.notshippedexitComponent.get_data_routes(this.get_date_current_format_day_mounth_year(this.date_selected));

  }

}
