import { NgModule } from '@angular/core';

import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { OffsidebarComponent } from './offsidebar/offsidebar.component';
import { FooterComponent } from './footer/footer.component';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { SharedModule } from '../shared/shared.module';
import {environment} from '../../environments/environment';
import { UserblockComponent } from './sidebar/userblock/userblock.component';
import { UserblockService } from './sidebar/userblock/userblock.service';
import { NewsComponent } from './news/news.component';
import { NotshippedComponent } from './notshipped/notshipped.component';
import { SendnotificationComponent } from './sendnotification/sendnotification.component';
import { NewsinvoiceComponent } from './newsinvoice/newsinvoice.component';
import { NewsadminComponent } from './newsadmin/newsadmin.component';
import { NotshippedentryComponent } from './notshippedentry/notshippedentry.component';
import { NotshippedexitComponent } from './notshippedexit/notshippedexit.component';
import { SendnotificationinvoiceComponent } from './sendnotificationinvoice/sendnotificationinvoice.component';
import { SendnotificationdriversComponent } from './sendnotificationdrivers/sendnotificationdrivers.component';
import { SendnotificationrouteComponent } from './sendnotificationroute/sendnotificationroute.component';
import { NotificationsadminComponent } from './notificationsadmin/notificationsadmin/notificationsadmin.component';
import { NewsuperadminComponent } from './newsuperadmin/newsuperadmin.component';


@NgModule({
    imports: [
        SharedModule,
        AngularFireModule.initializeApp(environment.firebase, 'mibuseta3'),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFirestoreModule
    ],
    providers: [
        UserblockService
    ],
    declarations: [
        LayoutComponent,
        SidebarComponent,
        HeaderComponent,
        OffsidebarComponent,
        FooterComponent,
        UserblockComponent,
        NewsComponent,
        NotshippedComponent,
        SendnotificationComponent,
        NewsinvoiceComponent,
        NewsadminComponent,
        NotshippedentryComponent,
        NotshippedexitComponent,
        SendnotificationinvoiceComponent,
        SendnotificationdriversComponent,
        SendnotificationrouteComponent,
        NotificationsadminComponent,
        NewsuperadminComponent
    ],
    exports: [
        LayoutComponent,
        SidebarComponent,
        HeaderComponent,
        OffsidebarComponent,
        FooterComponent,
        UserblockComponent
    ]
})
export class LayoutModule { }
