import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {PagesModule} from './pages/pages.module';
import {IndexComponent} from './index/index.component';
import {routes} from './routes';
import {NgxCaptchaModule} from 'ngx-captcha';



@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes),
    PagesModule,
    NgxCaptchaModule
  ],
  declarations: [IndexComponent],
  exports: [
    RouterModule
  ]
})

export class RoutesModule {}
