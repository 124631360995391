import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {NewsService} from '../../services/notification/news.service';
import {User} from '../../routes/administrate/class/class.user';
import {MatTableDataSource} from '@angular/material/table';
import {Newadmin} from '../class/class.newadmin';
import {TokenService} from '../../models/services/token.service';

@Component({
  selector: 'app-newsuperadmin',
  templateUrl: './newsuperadmin.component.html',
  styleUrls: ['./newsuperadmin.component.scss']
})
export class NewsuperadminComponent implements OnInit {
  private displayedColumns = new Array();
  private dataSource;
  private usersession;
  @ViewChild(MatPaginator, {}) paginator: MatPaginator;
  @Output() public count = new EventEmitter<number>();

  constructor(private newservice: NewsService, private token: TokenService) { }

  ngOnInit() {
    this.usersession = this.token.USER;
    this.loaddatafirebase();
  }

  loaddatafirebase() {
    this.newservice.get_data_noti_superadmin().subscribe(
        response => {
          let auxresp = [];
          response.forEach( r => {
            if (!r.hasOwnProperty('tipo')) {
              auxresp.push(r);
            }
          });
          this.count.emit(auxresp.length);
          this.load_data_table_pagination(auxresp);
        },
        error => {
        }
    );
  }

  load_data_table_pagination(data) {
    this.displayedColumns  = ['nombreConductor', 'dia', 'hora', 'titulo', 'descripcion'];
    this.dataSource = new MatTableDataSource<Newadmin>(data);
    this.dataSource.paginator = this.paginator;
  }

    actualizar(id) {
       // this.newservice.actualizarSuper(id);
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

}
