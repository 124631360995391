import {Component, OnInit, ViewChild} from '@angular/core';
import swal from 'sweetalert';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import {SendnotificationService} from '../../services/notification/sendnotification.service';
import {Notificationdriver} from '../class/class.notificationdriver';
import {TokenService} from '../../models/services/token.service';
import {UserInformation} from '../../routes/pages/login/class/class.login';

@Component({
  selector: 'app-sendnotificationdrivers',
  templateUrl: './sendnotificationdrivers.component.html',
  styleUrls: ['./sendnotificationdrivers.component.scss']
})
export class SendnotificationdriversComponent implements OnInit {

  /**
   * Variables del formulario de envio de novedades
   * Variables of the news submission form
   *
   * @private
   * @memberof SendnotificationdriversComponent
   */
  private title = '';
  private message = '';
  private input_search = '';

  private usersession: UserInformation;

  /**
   * Variables para tabla
   * Table variables
   *
   * @private
   * @memberof SendnotificationdriversComponent
   */
  private displayedColumns = new Array();
  private dataSource;
  private selection;

  @ViewChild(MatPaginator, {}) paginator: MatPaginator;

  constructor(
      private sendnotification: SendnotificationService,
      private tokenService: TokenService
  ) {
  }

  /**
   * Se ejecuta al cargar el componente
   * It is executed when the component is loaded
   *
   * @memberof SendnotificationdriversComponent
   */
  ngOnInit() {

    this.usersession = this.tokenService.USER;
    this.get_all_data();

  }

  /**
   * Filtro para la tabla
   * Table filter
   *
   * @param {string} filterValue
   * @memberof SendnotificationdriversComponent
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Carga la data en la tabla
   * Load the data into the table
   *
   * @param {*} data
   * @memberof SendnotificationdriversComponent
   */
  load_data_table_pagination(data) {

    this.displayedColumns = ['select', 'cedulaConductor', 'nombreConductor', 'nombreRuta'];
    this.dataSource = new MatTableDataSource<Notificationdriver>(data);
    this.dataSource.paginator = this.paginator;
    this.selection = new SelectionModel<Notificationdriver>(true, []);

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Notificationdriver): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.condigoConductor + 1}`;
  }

  /**
   * Obtiene la data de los representantes
   * Get the data of the representatives
   *
   * @memberof SendnotificationdriversComponent
   */
  get_all_data() {

    document.getElementById('cover').style.display = 'block';

    this.sendnotification.get_data_drivers_by_idcompany().subscribe(
        response => {
          //console.log(response);
          if (response.body.statusCode == 200) {
            this.load_data_table_pagination(response.body.result);
          }
          document.getElementById('cover').style.display = 'none';
        },
        error => {

          this.fun_load_swal('Aviso', 'Ha ocurrido un error con el servidor.', 'error');
          document.getElementById('cover').style.display = 'none';

        }
    );

  }

  /**
   * Escribe la notificacion en firebase
   * Write the notification in firebase
   *
   * @memberof SendnotificationdriversComponent
   */
  send_notification() {

    //console.log(this.selection.selected);
    if (this.selection.selected.length > 0) {

      var dato = this.get_date();

      for (let i = 0; i < this.selection.selected.length; i++) {

        var data = {
          mensaje: this.message,
          fecha: this.get_date(),
          hora: this.get_time(),
          idConductor: this.selection.selected[i].condigoConductor,
          leido: false,
          nombreConductor: this.selection.selected[i].nombreConductor,
          titulo: this.title,
          recibe: 'conductor_' + this.selection.selected[i].condigoConductor
        };

        this.sendnotification.add_data_novedades_drivers(data, dato).subscribe(
            response => {

            },
            error => {

            }
        );

      }

      this.fun_load_swal('Aviso', 'Se ha enviado correctamente.', 'success');
      this.reset_values();

    } else {
      this.fun_load_swal('Aviso', 'Debe seleccionar al menos un conductor.', 'info');
    }

  }

  /**
   * Resetea los valores del formulario para el envio de una nueva notificacion
   * Reset the form values for sending a new notification
   *
   * @memberof SendnotificationdriversComponent
   */
  reset_values() {
    this.selection.clear();
    this.title = '';
    this.message = '';
    this.input_search = '';
    this.applyFilter(this.input_search);
  }

  /**
   * Obtiene la fecha actual
   * Get the current date
   *
   * @returns
   * @memberof SendnotificationdriversComponent
   */
  get_date() {
    var date = new Date();

    var year = date.getFullYear();
    var mounth = date.getMonth() + 1;
    var day = date.getDate();

    return this.add_zero_date(day) + '-' + this.add_zero_date(mounth) + '-' + year;

  }

  /**
   * Obtiene la hora actual
   * Get the current time
   *
   * @returns
   * @memberof SendnotificationdriversComponent
   */
  get_time() {

    var date = new Date();

    var hour = date.getHours();
    var min = date.getMinutes();
    var sec = date.getSeconds();

    return this.add_zero_date(hour) + ':' + this.add_zero_date(min) + ':' + this.add_zero_date(sec);

  }

  /**
   * Agrega el 0 a cada valor de la hora o fecha
   * Add 0 to each time or date value
   *
   * @param {*} data
   * @returns
   * @memberof SendnotificationdriversComponent
   */
  add_zero_date(data) {
    return (data < 10) ? '0' + data.toString() : data;
  }

  //SWAL PARA MOSTRAR ERRORES O VALIDACIONES DE ENVIO DE DATOS A LOS SERVICIOS
  fun_load_swal(title, text, type) {
    swal({
      title: title,
      text: text,
      type: type,
      showCancelButton: false,
      confirmButtonColor: '#8edbff',
      confirmButtonText: 'Aceptar',
      //cancelButtonText: 'Cancelar',
      closeOnConfirm: true,
      closeOnCancel: true
    }, (isConfirm) => {

    });
  }


}
