import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'; // this is needed!
import {NgModule} from '@angular/core';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppComponent} from './app.component';
import {ChartsModule} from 'ng2-charts';

import {CoreModule} from './core/core.module';
import {LayoutModule} from './layout/layout.module';
import {SharedModule} from './shared/shared.module';
import {RoutesModule} from './routes/routes.module';
import {ListDriversComponent} from './routes/administrate/drivers/list-drivers/list-drivers.component';
import {MatFileUploadModule} from 'angular-material-fileupload';
import {environment} from '../environments/environment';
import {AgmCoreModule} from '@agm/core';
import {MatSortModule} from '@angular/material';
import {HeaderParamsService} from './models/services/header-params.service';
import {AgGridModule} from 'ag-grid-angular';
import {AngularFireStorageModule} from '@angular/fire/storage';
import { FormsModule } from '@angular/forms';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoadingSpinnerComponent

  ],
  imports: [
    FormsModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    LayoutModule,
    SharedModule.forRoot(),
    RoutesModule,
    MatFileUploadModule,
    AngularFireStorageModule,
    MatSortModule,
    AgGridModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgbModule,
    ChartsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.apikey,
      libraries: ['places']
    }),
  ],
  providers: [ListDriversComponent, { provide: HTTP_INTERCEPTORS, useClass: HeaderParamsService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
