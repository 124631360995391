export class Login {
    sub:             string;
    userInformation: UserInformation;
    exp:             number;
    iat:             number;
    jti:             string;
}

export class UserInformation {
    id:              number;
    name:            string;
    email:           null;
    state:           number;
    generalRol:      number;
    userPrivileges:  number;
    mib:             string;
    selectedCompany: Company;
    companies:       Company[];
    consortium:      Consortium;
}

export class Company {
    id:          number;
    companyName: string;
    rol:         Rol;
    city:        City;
    fireNumber: string;
    idCompanyType: number;
}

export class City {
    id:            number;
    name:          string;
    stateOfthis:   number;
    state:         null;
    supportNumber: string;
}

export class Rol {
    id:        number;
    name:      string;
    privilege: number;
}

export class Consortium {
    id:   number;
    name: string;
}
