// To parse this data:
//
//   import { Convert } from "./file";
//
//   const voucher = Convert.toVoucher(json);

import { Product } from "../class.product";

export class Voucher {
    city: string;
    companyName: string;
    date: Date;
    dateAccepted: Date;
    dateVoucher: string;
    driverId: number[];
    driverName: string[];
    hourVoucher: string;
    idAdvisor: number;
    idCompany: number;
    idPassenger: number;
    idRoute: number[];
    idVoucher: string;
    month: string;
    monthPage: string;
    nameAdvisor: string;
    namePassenger: string;
    nameRoute: string[];
    observation: string;
    schoolPeriod: string;
    status: string;
    statusVoucher: string;
    totalVoucher: number;
    typoTransaction: string;
    urlVoucher: string;
    nameVoucher: string;
    viewed: boolean;
    iva: number;
    totalValue: number;
    invoiceNumber: string;
    products: Product[];
    productName: string;
}

export class DriverRouteVoucher {
    idRoute: number;
    nameRoute: string;
    driverName: string;
    listVoucher: Voucher[];
}

// Converts JSON strings to/from your types
export class ConvertListVoucher {
    public static toVoucher(json: string): Voucher[] {
        const vouchers: Voucher[] = JSON.parse(json);

        return vouchers;
    }

    public static voucherToJson(value: Voucher[]): string {
        return JSON.stringify(value);
    }
}
