import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreDocument,
  DocumentData,
  QuerySnapshot,
} from "angularfire2/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import {
  HttpResponse,
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Howl, Howler } from "howler";
import { Responseservice } from "../../routes/school/class/class.responseservice";
import { TokenService } from "../../models/services/token.service";
import * as firebase from "firebase";
import { NewsinvoiceComponent } from "../../layout/newsinvoice/newsinvoice.component";
import { AdditionalInfoInvoice, InvoiceMassive } from "../../routes/administrate/class/class.invoice";

@Injectable({
  providedIn: "root",
})
export class NewsService {
  urlService = environment.urlServer;
  urlMicroServices = environment.urlMicroServices;
  private user;
  private city;
  private namecompany;

  constructor(
    private db: AngularFirestore,
    private authdirebase: AngularFireAuth,
    private http: HttpClient,
    private token: TokenService
  ) {
    this.user = this.token.USER;
    this.city = this.user.selectedCompany.city.name;
    this.namecompany = this.user.selectedCompany.companyName;
  }

  get_data_drivers_by_idcompany(): Observable<HttpResponse<any>> {
    console.log("SERVICE DE GET DRIVER ID FOR PAGE!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    return this.http.get<any>(
      this.urlService +
      "report/v1.0/driversIdByCompanyId/" +
      this.user.selectedCompany.id,
      { observe: "response" }
    );
  }

  get_data_firebase_report_news(date: Date): Observable<HttpResponse<any>> {
    // console.log(this.urlService + 'firebase/v1.0/fechasReporteNovedades/' + this.city + '/' + this.namecompany+'/'+date);
    return this.http.get<any>(
      this.urlService +
      "firebase/v1.0/fechasReporteNovedades/" +
      this.city +
      "/" +
      this.namecompany +
      "/" +
      date,
      { observe: "response" }
    );
  }


  update_school(
    idPassenger: string,
    idNewCompany: string
  ): Observable<Responseservice> {
    // @ts-ignore
    return this.http.post<any>(
      this.urlService +
      "passenger/v1.0/passengerCompany/" +
      idPassenger +
      "/" +
      idNewCompany
    );
  }
  // /CUENCA/novedades/CIRO/admin/07-06-2023
  getDataNoveltyAdmin(
    cityCompany: string,
    nameCompany: string,
    dateNovelty: string
  ): Observable<any> {
    return new Observable((observer) => {
      this.db
        .collection(cityCompany)
        .doc("novedades")
        .collection(nameCompany)
        .doc("admin")
        .collection(dateNovelty)
        .snapshotChanges()
        .subscribe(
          (response) => {
            let dataNoveltyAdmin = new Array();
            if (response.length > 0) {
              this.adddatafirebasetoarraytable(response, dataNoveltyAdmin);
              return observer.next(dataNoveltyAdmin);
            } else {
              return observer.next(dataNoveltyAdmin);
            }
          },
          (error) => { }
        );
    });
  }

  changeStatusDataNoveltyAdmin(
    cityCompany: string,
    nameCompany: string,
    dateNovelty: string,
    id
  ) {
    // return new Observable((observer) => {
    this.db
      .collection(cityCompany)
      .doc("novedades")
      .collection(nameCompany)
      .doc("admin")
      .collection(dateNovelty)
      .doc(id)
      .update({ leido: true });
  }

  get_data_news_admin(namecompany: any): Observable<any> {
    return new Observable((observer) => {
      //this.authdirebase.auth.signInWithEmailAndPassword(environment.emailFirebase,environment.passwordFirebase).then(value => {//login as read only
      this.db
        .collection("app_support")
        .doc("error_ruta")
        .collection(namecompany.toString())
        .snapshotChanges()
        .subscribe(
          (response) => {
            let datanewsadmin = new Array();
            if (response.length > 0) {
              this.adddatafirebasetoarraytable(response, datanewsadmin);
              return observer.next(datanewsadmin);
            } else {
              return observer.next(datanewsadmin);
            }
          },
          (error) => { }
        );
    });
  }

  getListNotifyAdminSupport(): Observable<any> {
    return new Observable((observer) => {
      this.db
        .collection("app_support")
        .doc("notify_admin")
        .collection("notify", (ref) =>
          ref.where("idCompany", "==", this.user.selectedCompany.id)
        )
        .snapshotChanges()
        .subscribe(
          (response) => {
            // console.log("res", response);
            let listData: any = [];
            for (let i = 0; i < response.length; i++) {
              listData.push(response[i].payload.doc.data());
            }
            return observer.next(listData);
          },
          (error) => { }
        );
    });
  }

  getCountVouchersPending(idCompany: number, period: string) {
    return new Observable((observer) => {
      this.db
        .collection("companies_voucher")
        .doc(`idCompany_${idCompany}`)
        .collection(`period_${period}`)
        .snapshotChanges()
        .subscribe(
          (resp) => {
            let listData: any = [];
            for (let i = 0; i < resp.length; i++) {
              listData.push(resp[i].payload.doc.data());
            }
            return observer.next(listData);
          },
          (error) => { }
        );
    });
  }

  getCompanyVoucherChangeStatus(idCompany: number, status: boolean) {
    const voucherCompany = this.db
      .collection("companies_voucher_active")
      .doc(`idCompany_${idCompany}`);
    voucherCompany.set({ status: status });
  }

  getCompanyFacturationChangeStatus(idCompany: number, status: boolean) {
    const voucherCompany = this.db
      .collection("companies_facturation_active")
      .doc(`idCompany_${idCompany}`);
    voucherCompany.set({ status: status });
  }

  getCompanyPriceTransportChangeStatus(idCompany: number, status: boolean) {
    const voucherCompany = this.db
      .collection("companies_pricetransport_active")
      .doc(`idCompany_${idCompany}`);
    voucherCompany.set({ status: status });
  }

  getCompanyActiveModule(idCompany: number, type: string) {
    return new Observable((observer) => {
      const collection =
        type == "facturation"
          ? "companies_facturation_active"
          : type == "voucher"
            ? "companies_voucher_active"
            : "companies_pricetransport_active";
      this.db
        .collection(collection)
        .doc(`idCompany_${idCompany}`)
        .get()
        .subscribe(
          (resp) => {
            observer.next(resp.data());
          },
          (error) => {
            return observer.next(null);
          }
        );
    });
  }

  getCompanyActiveVoucher(idCompany: number) {
    return new Observable((observer) => {
      this.db
        .collection("companies_voucher_active")
        .doc(`idCompany_${idCompany}`)
        .get()
        .subscribe(
          (resp) => {
            observer.next(resp.data());
          },
          (error) => {
            return observer.next(null);
          }
        );
    });
  }

  getListInvoices(idCompany: number) {
    return new Observable((observer) => {
      this.db
        .collection("invoices")
        .doc(`idCompany_${idCompany}`)
        .collection('invoice')
        .snapshotChanges()
        .subscribe(
          (resp) => {
            let listData: any = [];
            for (let i = 0; i < resp.length; i++) {
              listData.push(resp[i].payload.doc.data());
            }
            return observer.next(listData);
          },
          (error) => { }
        );
    });
  }

  getListVouchers(idCompany: number, period: string) {
    console.log("GET FROM FIREBASE");
    return new Observable((observer) => {
      this.db
        .collection("companies_voucher")
        .doc(`idCompany_${idCompany}`)
        .collection(`period_${period}`, (ref) => ref.orderBy("date", "desc"))
        .snapshotChanges()
        .subscribe(
          (resp) => {
            // console.log("RESPONSE FROM FIREBASE");
            let listData: any = [];
            for (let i = 0; i < resp.length; i++) {
              listData.push(resp[i].payload.doc.data());
              // console.log(resp[i].payload.doc.data());
            }
            return observer.next(listData);
          },
          (error) => { }
        );
    });
  }

  getListVoucherFilter(idCompany: number, period: string, monthPage: string) {
    return new Observable((observer) => {
      this.db
        .collection("companies_voucher")
        .doc(`idCompany_${idCompany}`)
        .collection(
          `period_${period}`,
          (ref) =>
            ref
              .where("statusVoucher", "==", "PENDIENTE")
              .where("monthPage", "==", monthPage)
          // .orderBy("date", "desc")
        )
        .snapshotChanges()
        .subscribe(
          (resp) => {
            let listData: any = [];
            for (let i = 0; i < resp.length; i++) {
              listData.push(resp[i].payload.doc.data());
            }
            return observer.next(listData);
          },
          (error) => { }
        );
    });
  }

  getListVouchersByIdPassenger(
    idCompany: number,
    period: string,
    idPassenger: number
  ) {
    return new Observable((observer) => {
      this.db
        .collection("companies_voucher")
        .doc(`idCompany_${idCompany}`)
        .collection(`period_${period}`, (ref) =>
          ref.where("idPassenger", "==", Number(idPassenger))
        )
        .snapshotChanges()
        .subscribe(
          (resp) => {
            let listData: any = [];
            for (let i = 0; i < resp.length; i++) {
              listData.push(resp[i].payload.doc.data());
            }
            return observer.next(listData);
          },
          (error) => { }
        );
    });
  }

  updateStatusAcceptVoucher(
    idVoucher: string,
    idCompany: number,
    period: string,
    observationAdmin: string,
    dataAccepted: Date
  ) {
    const voucher = this.db
      .collection("companies_voucher")
      .doc(`idCompany_${idCompany}`)
      .collection(`period_${period}`)
      .doc(idVoucher);
    return voucher.update({
      statusVoucher: "ACEPTADO",
      observationAdmin: observationAdmin,
      dateAccepted: `${dataAccepted}`,
    });
  }

  updateStatusdeclineVoucher(
    idVoucher: string,
    idCompany: number,
    period: string,
    observationAdmin: string,
    dataDecline: Date
  ) {
    const voucher = this.db
      .collection("companies_voucher")
      .doc(`idCompany_${idCompany}`)
      .collection(`period_${period}`)
      .doc(idVoucher);
    return voucher.update({
      statusVoucher: "RECHAZADO",
      observationAdmin: observationAdmin,
      dateDecline: `${dataDecline}`,
    });
  }

  updateStatusViewVoucher(
    idVoucher: string,
    idCompany: number,
    period: string
  ) {
    const voucher = this.db
      .collection("companies_voucher")
      .doc(`idCompany_${idCompany}`)
      .collection(`period_${period}`)
      .doc(idVoucher);
    return voucher.update({ viewed: true });
  }

  get_data_noti_admin(namecompany: any): Observable<any> {
    return new Observable((observer) => {
      // tslint:disable-next-line:max-line-length
      this.db
        .collection("app_support")
        .doc("notification_ruta")
        .collection(namecompany, (ref) => ref.orderBy("dia", "asc"))
        .snapshotChanges()
        .subscribe(
          (response) => {
            let datanewsadmin = new Array();
            if (response.length > 0) {
              this.adddatafirebasetoarraytable(response, datanewsadmin);

              return observer.next(datanewsadmin);
            } else {
              return observer.next(datanewsadmin);
            }
          },
          (error) => { }
        );
    });
  }

  getDataErrorCompany(idCompany: number): Observable<any> {
    return new Observable((observer) => {
      this.db
        .collection("company_support")
        .doc(`idCompany_${idCompany}`)
        .collection("notify_company", (ref) =>
          ref.where("status", "==", "ACTIVE")
        )
        .snapshotChanges()
        .subscribe(
          (response) => {
            let datanewsadmin = new Array();
            if (response.length > 0) {
              this.adddatafirebasetoarraytable(response, datanewsadmin);
              return observer.next(datanewsadmin);
            } else {
              return observer.next(datanewsadmin);
            }
          },
          (error) => { }
        );
    });
  }

  getDataNoAssistance(nameCompany: string): Observable<any> {
    return new Observable((observer) => {
      // tslint:disable-next-line:max-line-length
      this.db
        .collection("app_support")
        .doc("abscence_notifications")
        .collection(nameCompany, (ref) => ref.orderBy("id", "desc"))
        .snapshotChanges()
        .subscribe(
          (response) => {
            let datanewsadmin = new Array();
            if (response.length > 0) {
              this.adddatafirebasetoarraytable(response, datanewsadmin);
              return observer.next(datanewsadmin);
            } else {
              return observer.next(datanewsadmin);
            }
          },
          (error) => { }
        );
    });
  }

  getDataExtracurricular(nameCompany: string): Observable<any> {
    return new Observable((observer) => {
      // tslint:disable-next-line:max-line-length
      this.db
        .collection("app_support")
        .doc("extracurricular_notifications")
        .collection(nameCompany, (ref) => ref.orderBy("id", "desc"))
        .snapshotChanges()
        .subscribe(
          (response) => {
            let datanewsadmin = new Array();
            if (response.length > 0) {
              this.adddatafirebasetoarraytable(response, datanewsadmin);
              return observer.next(datanewsadmin);
            } else {
              return observer.next(datanewsadmin);
            }
          },
          (error) => { }
        );
    });
  }

  updateDisabledNotifyErrorCompany(idCompany: number, idDocument: string) {
    const document = this.db
      .collection("company_support")
      .doc(`idCompany_${idCompany}`)
      .collection("notify_company")
      .doc(idDocument);
    return document.update({ status: "DISABLED", statusNotify: "RESOLVED" });
  }

  updateViewNotifyErrorCompany(idCompany: number, idDocument: string) {
    const document = this.db
      .collection("company_support")
      .doc(`idCompany_${idCompany}`)
      .collection("notify_company")
      .doc(idDocument);
    return document.update({ read: true });
  }

  get_data_delete_passenger(namecompany: any): Observable<any> {
    return new Observable((observer) => {
      this.db
        .collection("app_support")
        .doc("notifications_admin")
        .collection(namecompany, (ref) => ref.where("estado", "==", true))
        .snapshotChanges()
        .subscribe(
          (response) => {
            let datanewsadmin = new Array();
            if (response.length > 0) {
              this.adddatafirebasetoarraytable(response, datanewsadmin);
              return observer.next(datanewsadmin);
            } else {
              return observer.next(datanewsadmin);
            }
          },
          (error) => { }
        );
    });
  }

  get_data_change_school(namecompany: any): Observable<any> {
    //mmm
    return new Observable((observer) => {
      // tslint:disable-next-line:max-line-length
      this.db
        .collection("app_support")
        .doc("notification_change_school")
        .collection(namecompany, (ref) => ref.orderBy("dia", "asc"))
        .snapshotChanges()
        .subscribe(
          (response) => {
            let datanewsadmin = new Array();
            if (response.length > 0) {
              this.adddatafirebasetoarraytable(response, datanewsadmin);
              return observer.next(datanewsadmin);
            } else {
              return observer.next(datanewsadmin);
            }
          },
          (error) => { }
        );
    });
  }

  get_data_noti_superadmin(): Observable<any> {
    return new Observable((observer) => {
      // tslint:disable-next-line:max-line-length
      this.db
        .collection("app_support")
        .doc("error_app")
        .collection("error", (ref) => ref.where("estado", "==", "PENDIENTE"))
        .snapshotChanges()
        .subscribe(
          (response) => {
            let datanewsadmin = new Array();
            if (response.length > 0) {
              this.adddatafirebasetoarraytable(response, datanewsadmin);
              return observer.next(datanewsadmin);
            } else {
              return observer.next(datanewsadmin);
            }
          },
          (error) => { }
        );
    });
  }

  deletePassengerAssignedOfRoute(namecompany: any, id) {
    const document = this.db
      .collection("app_support")
      .doc("notification_ruta")
      .collection(namecompany)
      .doc(id);

    return document
      .delete()
      .then(() => { })
      .catch((error) => { });
  }

  updateStatusNotifycation(namecompany: string, id: string) {
    const info = this.db
      .collection("app_support")
      .doc("notifications_admin")
      .collection(namecompany)
      .doc(id);
    return info.update({ estado: false });
  }

  updateStatusReadNotifycation(namecompany: string, id: string) {
    const info = this.db
      .collection("app_support")
      .doc("notifications_admin")
      .collection(namecompany)
      .doc(id);
    return info.update({ leido: true });
  }

  actualizar(namecompany: any, leido: boolean, id) {
    const info = this.db
      .collection("app_support")
      .doc("notification_ruta")
      .collection(namecompany)
      .doc(id);
    return info.update({ leido: leido });
  }

  actualizar2(tipo: string, namecompany: any, leido: boolean, id, status: boolean) {
    let doc_notification = "notification_change_school";
    if (tipo == "NOTIFICACION DE AUSENCIA")
      doc_notification = "abscence_notifications";
    if (tipo == "NOTIFICACION DE EXTRACURRICULARES")
      doc_notification = "extracurricular_notifications";
    const info = this.db
      .collection("app_support")
      .doc(doc_notification)
      .collection(namecompany)
      .doc(id);
    return info.update({ leido: leido, status: status ? "aproved" : "denied" });
  }

  updateNotify(id) {
    this.db
      .collection("app_support")
      .doc("notify_admin")
      .collection("notify")
      .doc(id)
      .update({
        read: true,
      });
    // .get().subscribe(r => console.log("data", r.data()));
  }

  actualizarSuper(id) {
    const info = this.db
      .collection("app_support")
      .doc("error_app")
      .collection("error")
      .doc(id);
    return info.update({ estado: "ACEPTADO" });
  }

  get_sound() {
    const sound = new Howl({
      src: ["../../assets/sound/notificacion.mp3"],
    });

    sound.play();

    /* const audio = new Audio('../../assets/sound/notificacion.mp3');
    audio.play(); */
  }

  get_data_report(date: Date): Observable<any> {
    return new Observable((observer) => {
      const datanewsadmin = [];
      this.get_data_firebase_report_news(date).subscribe(
        (response) => {
          // console.log(response);
          // tslint:disable-next-line:triple-equals
          if (response.body.statusCode == "200") {
            for (
              let i = 0;
              i < response.body.result[0].listaAdmins.length;
              i++
            ) {
              this.adddatafirebasetoarraytablereportadmin(
                response.body.result[0].listaAdmins[i].detalles,
                datanewsadmin
              );
              for (
                let y = 0;
                y < response.body.result[0].listarepresentantes.length;
                y++
              ) {
                // tslint:disable-next-line:max-line-length
                this.adddatafirebasetoarraytablereportinvoice(
                  response.body.result[0].listarepresentantes[y]
                    .detalleListadoRepresentantes,
                  datanewsadmin
                );
              }
            }
          }
          return observer.next(datanewsadmin);
        },
        (error) => {
          // console.log(error);
        }
      );
    });
  }

  get_data_news_representatives(date): Observable<any> {
    return new Observable((observer) => {
      //this.authdirebase.auth.signInWithEmailAndPassword(environment.emailFirebase,environment.passwordFirebase).then(value => {//login as read only
      // console.log("connect to firebase !!!!!!!!!!!!");
      this.db
        .collection(this.city.toString())
        .doc("novedades")
        .collection(this.namecompany.toString())
        .doc("representantes")
        .collection(date)
        .snapshotChanges()
        .subscribe(
          (response) => {
            // console.log(response);
            let datanewsadmin = new Array();

            if (response.length > 0) {
              this.adddatafirebasetoarraytable(response, datanewsadmin);
              return observer.next(datanewsadmin);
            } else {
              return observer.next(datanewsadmin);
            }
          },
          (error) => {
            console.log("ERROR DEL SERVICIO !!!!!");
          }
        );

      /* }).catch(err => {
             console.log('Something went wrong getting all routes of a school:',err.message);
          });  */
    });
  }

  change_status_data_news_representatives(id, date: string) {
    this.db
      .collection(this.city.toString())
      .doc("novedades")
      .collection(this.namecompany.toString())
      .doc("representantes")
      .collection(date)
      .doc(id)
      .update({
        leidoWeb: true,
      });
  }

  get_data_count_news_admin(
    city: any,
    namecompany: any,
    date: string
  ): Observable<any> {
    return new Observable((observer) => {
      //this.authdirebase.auth.signInWithEmailAndPassword(environment.emailFirebase,environment.passwordFirebase).then(value => {//login as read only
      this.db
        .collection(city.toString())
        .doc("novedades")
        .collection(namecompany.toString())
        .doc("admin")
        .collection(date)
        .snapshotChanges()
        .subscribe(
          (response) => {
            //console.log(response);
            let datanewsadmin = new Array();

            datanewsadmin.push({
              total: response.length,
            });

            return observer.next(datanewsadmin);
          },
          (error) => { }
        );

      /*  }).catch(err => {
              //console.log('Something went wrong getting all routes of a school:',err.message);
           });  */
    });
  }

  adddatafirebasetoarraytable(data, dataarray) {
    for (let i = 0; i < data.length; i++) {
      let dataSave = data[i].payload.doc.data();
      dataSave["id"] = data[i].payload.doc.id;
      dataarray.push(dataSave);
    }
    this.get_sound();
    //console.log(this.datafirebase);
  }

  adddatafirebasetoarraytablereportadmin(data, dataarray) {
    for (let i = 0; i < data.length; i++) {
      dataarray.push({
        title: data[i].titulo,
        start: this.change_format_date(data[i].dia, data[i].hora),
        data: data[i],
        type: "admin",
        backgroundColor: "#f56954", //red
        borderColor: "#f56954", //red
      });
    }
    //console.log(this.datafirebase);
  }

  adddatafirebasetoarraytablereportinvoice(data, dataarray) {
    for (let i = 0; i < data.length; i++) {
      //console.log(data[i]);
      var data_object = {
        title: data[i].titulo,
        start: this.change_format_date(data[i].dia, data[i].hora),
        data: data[i],
        type: "advisor",
        backgroundColor: "#f39c12", //red
        borderColor: "#f39c12", //red
      };
      //console.log(dataarray);
      if (this.valid_items_with_the_same_time(data_object, dataarray) == true) {
        dataarray.push(data_object);
      }
    }
    //console.log(this.datafirebase);
  }

  valid_items_with_the_same_time(data, array) {
    for (let i = 0; i < array.length; i++) {
      if (
        array[i].data.hora == data.data.hora &&
        array[i].data.envia == data.data.envia &&
        array[i].data.dia == data.data.dia
      ) {
        return false;
      }
    }

    return true;
  }

  get_day_current() {
    var date = new Date();
    return date.getDate();
  }

  get_year_current() {
    var date = new Date();
    return date.getFullYear();
  }

  get_mounth_current() {
    var date = new Date();
    return this.add_zero_date(date.getMonth() + 1);
  }

  add_zero_date(data) {
    return data < 10 ? "0" + data.toString() : data;
  }

  change_format_date(date: string, hora: string) {
    var year = date.substring(6, 10);
    var mounth = date.substring(3, 5);
    var day = date.substring(0, 2);

    var fecha = year + "-" + mounth + "-" + day;
    var fechafinal = fecha + " " + hora;

    var date2 = new Date(fechafinal);

    return date2;
  }
}
