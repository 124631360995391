import {Component, OnInit} from '@angular/core';
import {SettingsService} from '../../../core/settings/settings.service';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {CustomValidators} from 'ng2-validation';
import * as $ from 'jquery';
import {TokenService} from '../../../models/services/token.service';
import {Menu} from '../../administrate/class/class.menu';
import {AcademicPeriodService} from '../../../services/academic-period/academic-period.service';
import {menuBlocked, menuDisabled} from '../../menu';
import {LoginService} from '../../../services/login/login.service';
import {AuthService} from '../../../models/services/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    title = 'auth';
    errorFlag = false;
    errorMsj: String;
    email = new FormControl('');
    password = new FormControl('');
    valForm: FormGroup;

    constructor(public settings: SettingsService,
                fb: FormBuilder,
                private authService: AuthService,
                private loginService: LoginService,
                private _aS: AcademicPeriodService,
                private _authService: TokenService) {
        // tslint:disable-next-line:no-unused-expression
        sessionStorage.getItem('userInformation') && sessionStorage.removeItem('userInformation');
        // tslint:disable-next-line:no-unused-expression
        sessionStorage.getItem('menu_submn') && sessionStorage.removeItem('menu_submn');
        this.valForm = fb.group({
            email: ['', Validators.compose([Validators.required, CustomValidators.email])],
            password: ['', Validators.required],
        });
    }

    public ngOnInit() {
        $(document).ready(function () {
            $('#btnlogin').click(function () {
            });

            // show password
            $('.check').on('change', function () {
                if ($(this).prop('checked')) {
                    $('#password').attr('type', 'text');
                } else {
                    $('#password').attr('type', 'password');
                }
            });

            $('.ojito').on('click', function () {
                if (this.clearPasswd) {
                    this.clearPasswd = !this.clearPasswd;
                    $('#password').attr('type', 'text');
                } else {
                    this.clearPasswd = !this.clearPasswd;
                    $('#password').attr('type', 'password');
                }
            });
        });
    }

    /**
     * submit form
     * @param $ev
     * @param value
     * */
    submitForm($ev, value: any) {
        document.getElementById('cover').style.display = 'block';
        $ev.preventDefault();
        // tslint:disable-next-line:forin
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            this.loginJWT(value.email, value.password);
        }
    }

  /**Login JWT**/
  loginJWT(email: string, password: string) {
    this.loginService.userLoginJWT({ email, password }).subscribe(
      (response) => {
        if (response.statusCode === "200") {
          this.errorFlag = false;
          this.errorMsj = "";
          this._authService.saveUserToken(response.accessToken.toString());
          this._authService.saveUserData(response.accessToken.toString());

          if (this._authService.USER.generalRol) {

            this.get_menu_submenu(this._authService.USER.generalRol).then(
              () => {
                document.getElementById("cover").style.display = "none";
              }
            );
          } else {
            if (this._authService.USER.selectedCompany) {
              this.get_menu_submenu(this._authService.USER.selectedCompany.rol.id).then(() => {
                  document.getElementById('cover').style.display = 'none';
              });
            } else {
              if (this._authService.USER.companies[0]) {
                // this.fun_set_selected_company(this._authService.USER, this._authService.USER.companies[0]);
                this.get_menu_submenu(
                  this._authService.USER.companies[0].rol.id
                ).then(() => {
                  document.getElementById("cover").style.display = "none";
                });
              } else {
                this.errorFlag = true;
                this.errorMsj = "Verificar las credenciales de acceso.";
                document.getElementById("cover").style.display = "none";
              }
            }
          }
          let currentDate: Date = new Date();
          // const ip = req.headers['X-Forwarded-For'];
          const device = navigator.userAgent;
          this.loginService.saveLoginRecord(
            currentDate,
            response.mensaje,
            email,
            device,
            "Inicio Sesion"
          );
          document.getElementById("cover").style.display = "none";
        } else {
          this.errorFlag = true;
          this.errorMsj = response.error;
          document.getElementById("cover").style.display = "none";
        }
      },
        (error) => {
        if (error) {
          this.errorFlag = true;
          this.errorMsj = "Verificar las credenciales de acceso.";
          document.getElementById("cover").style.display = "none";
        }
      },
      () => {}
    );
  }

    /**
     * Call service for load menu and submenu list
     * */
    async get_menu_submenu(idRol: number) {
        const resp = await this._aS.getCurrentPeriodAcademicStatus(this._authService.USER.selectedCompany.id);
        let menu: Menu[] = [];
        this.authService.getrolMenuSubmenu(idRol).subscribe(resultMenu => {
                // recupero menus
                if (resultMenu.body.result.length) {
                    const obj = resultMenu.body.result as Menu[];
                    this.errorFlag = false;
                    this.errorMsj = '';
                    // compruebo si es administrador
                    if (this._authService.USER.generalRol === 1) {
                        menu = obj;
                    } else {
                      // si tengo datos de un periodo anterior
                      // if (idRol != 14 && idRol != 16 && idRol != 17 && idRol != 18 && idRol != 11) {
                        if (resp['result'][0] != undefined || resp['result'][0] != null) {
                            if (resp['statusCode'] === '200') {
                                sessionStorage.setItem('academic_period', JSON.stringify(resp['result'][0]));
                                // si se encuentra activo el periodo, muestro el menu correcto
                                if (resp["result"][0]["academicPeriodStatus"]) {
                                    menu = obj;
                                } else {
                                    menu = menuBlocked;
                                }
                            } else {
                                sessionStorage.setItem('academic_period', JSON.stringify({}));
                                menu = menuDisabled;
                            }
                        } else {
                            menu = obj;
                        }
                    }
                    sessionStorage.setItem('menu_submn', JSON.stringify(menu));
                    location.assign(menu[0].link.substring(1, menu[0].link.length));
                } else {
                    this.errorFlag = true;
                    this.errorMsj = 'Fallo en la autenticación intente nuevamente.';
                }
            }, error => {
                if (error) {
                    this.errorFlag = true;
                    this.errorMsj = 'Error en el servidor.';
                }
            }, () => {
            }
        );
    }
}
