import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserBlockService {

  constructor( private http: HttpClient) { }

  urlService = environment.urlServer;

  get_qr(
      ueCode: string
  ): Observable<HttpResponse<any>> {
    return this.http
        .get<any>(this.urlService + 'qrServices/v1.0/getDynamicLinkQR/' + ueCode,
            // 'http://192.168.1.24:8080/mibusetabackend/rest/qrServices/v1.0/getDynamicLinkQR/' + ueCode,
            {observe: 'response'}
        );
  }
}
