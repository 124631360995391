import { Component, OnInit, ViewChild, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { User } from '../../routes/administrate/class/class.user';
import { NewsService } from '../../services/notification/news.service';
import { Newinvoice } from '../class/class.newinvoice';
import { Observable } from 'rxjs/Observable';
import {Howl, Howler} from 'howler';
import {TokenService} from '../../models/services/token.service';

@Component({
  selector: 'app-newsinvoice',
  templateUrl: './newsinvoice.component.html',
  styleUrls: ['./newsinvoice.component.scss']
})
export class NewsinvoiceComponent implements OnInit, OnChanges {

  /**
   * Variables de la tabla de material desing
   * Variables in the material design table
   *
   * @private
   * @memberof NewsinvoiceComponent
   */
  private displayedColumns = new Array();
  private dataSource;

  /**
   * Variable que contiene la informacion del usuario logeado
   * Variable that contains the logged-in user information
   *
   * @private
   * @type {User}
   * @memberof NewsinvoiceComponent
   */
  private usersession;

  /**
   * Variable en la que se almacena las novedades enviadas al representante
   * Variable in which the news sent to the representative is stored
   *
   * @private
   * @memberof NewsinvoiceComponent
   */
  private datafirebase = new Array();

  /**
   * Contador de novedades enviadas al representante
   * News counter sent to the representative
   *
   * @memberof NewsinvoiceComponent
   */
  @Output() public count = new EventEmitter<number>();

  /**
   * Valida si se visualiza las novedades para el contador, si es false es no vista y true no vista
   */
  @Input() public openNoveltiesModal = new EventEmitter<boolean>();
  @Input() public selectedItem = new EventEmitter<number>();

  /**
   * Pagina la data en la tabla de material desing
   * Page the data in the desing material table
   *
   * @type {MatPaginator}
   * @memberof NewsinvoiceComponent
   */
  @ViewChild(MatPaginator, {}) paginator: MatPaginator;
  countNovelties: number = 0;

  constructor(
    private newservice: NewsService,
    private token: TokenService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    // this.loaddatafirebase();
    console.log("cgane");
    
  }

  ngOnInit() {
    this.usersession = this.token.USER;
    this.loaddatafirebase();
  }

  get_sound(){
    var sound = new Howl({
     src: ['../../assets/sound/notificacion.mp3']
   });

   sound.play(); 

   /* const audio = new Audio('../../assets/sound/notificacion.mp3');
   audio.play(); */
 }

  /**
   * Carga la data en la tabla y la pagina
   * Load the data in the table and page
   *
   * @param {*} data
   * @memberof NewsinvoiceComponent
   */
  load_data_table_pagination(data){
    //console.log(data)
    this.displayedColumns  = ['nombreConductor', 'dia', 'hora', 'titulo', 'mensaje'];
    this.dataSource = new MatTableDataSource<Newinvoice>(data);
    this.dataSource.paginator = this.paginator;

  }
  
  /**
   * Consulta los conductores que pertenecen a la compañia
   * Check the drivers that belong to the company
   *
   * @memberof NewsinvoiceComponent
   */
  loaddatafirebase(){
    this.countNovelties = 0;
    this.newservice.get_data_drivers_by_idcompany().subscribe(
      response => {
        if(response.body.statusCode == 200){
          console.log();
          if(response.body.result[0] != null){
            for(let i = 0; i < response.body.result[0].length; i++){
              this.load_data_firebase_by_id_invoice(response.body.result[0][i].id);
            }
          }
        }

      },
      error => {

      }
    )
      
  }

  /**
   * Obtiene las novedades de firebase
   * Get the news from firebase
   *
   * @param {*} iddrivers
   * @returns {*}
   * @memberof NewsinvoiceComponent
   */
  load_data_firebase_by_id_invoice(iddrivers) : any{

    var date = this.get_date()+"_conductor_"+iddrivers;
    this.newservice.get_data_news_representatives(date).subscribe(
      response => {
        //console.log(response);
        if(response.length > 0){
          for(let i = 0; i < response.length; i++){
            //console.log(data[i]);
            if(this.valid_items_with_the_same_time(response[i]) == true){
              this.datafirebase.push(response[i]);
              this.get_sound();
              this.countNovelties += response[i]['leidoWeb'] == false ? 1 : 0;
            }
            if (this.openNoveltiesModal && !response['leidoWeb'] && Number(this.selectedItem) == 0) {
              this.newservice.change_status_data_news_representatives(response[i]['id'], date);
              this.loaddatafirebase();
            }
          }
          
          this.count.emit(this.countNovelties);
          this.load_data_table_pagination(this.datafirebase);
        }
        
      },
      error => {

      }
    )
    
  }

  /**
   * Valida que no se repitan las novedades
   * Validate that the novelties are not repeated
   *
   * @param {*} data
   * @returns
   * @memberof NewsinvoiceComponent
   */
  valid_items_with_the_same_time(data){

    for(let i = 0; i < this.datafirebase.length; i++){
      if((this.datafirebase[i].hora == data.hora) && (this.datafirebase[i].envia == data.envia)){
        return false;
      }
    }

    return true;
  }

  /**
   * Obtiene la fecha actual
   * Get the current date
   *
   * @returns
   * @memberof NewsinvoiceComponent
   */
  get_date(){
    var date = new Date();

    var year = date.getFullYear();
    var mounth = date.getMonth()+1;
    var day = date.getDate();

    return this.add_zero_date(day)+"-"+this.add_zero_date(mounth)+"-"+year;

  }

  /**
   * Agrega el cero a cualquier valor de una fecha o hora
   * Add zero to any value of a date or time
   *
   * @param {*} data
   * @returns
   * @memberof NewsinvoiceComponent
   */
  add_zero_date(data){
    return (data < 10) ? "0" + data.toString() : data;
  }

}


