import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import {
  HttpResponse,
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { Consortium } from "../../../routes/administrate/class/class.consortium";
import {
  timeout_services,
  environment
} from "../../../../environments/environment";
import { catchError, timeout, retry } from "rxjs/operators";

@Injectable()
export class UserblockService {
  urlService = environment.urlServer;
  public userBlockVisible: boolean;
  constructor(private http: HttpClient) {
    // initially visible
    this.userBlockVisible = true;
  }

  getVisibility() {
    return this.userBlockVisible;
  }

  setVisibility(stat = true) {
    this.userBlockVisible = stat;
  }

  toggleVisibility() {
    this.userBlockVisible = !this.userBlockVisible;
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
