import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {TokenService} from '../models/services/token.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private token: TokenService) {
  }

  /**
   * Can activate route
   * @param route
   * @param state
   * */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // if the user is logged
    if (this.token.USER) {
      const routeString = route.parent.url + '/' + route.url;
      const subMenuByUser = sessionStorage.getItem('menu_submn');
      // verify if the user has access in this path
      if (subMenuByUser.search(routeString) !== -1) {
        return true;
      } else {
        // is false and dont let the user enter in the path
        this.router.navigate(['/home'], {queryParams: {returnUrl: state.url}}); // navigate to home
        return false;
      }
    }// not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}}); // navigate to login
    // is false and dont let the user enter in the path
    return false;
  }
}
