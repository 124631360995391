import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoadingService } from '../../services/loading.service';
import { User } from 'firebase';
import { AcademicPeriodService } from '../../services/academic-period/academic-period.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  menuItems: Array<any>;
  private _loading = new BehaviorSubject<boolean>(false);
  private _aS: AcademicPeriodService; // Definir _aS como una propiedad privada

  constructor(private loadingService: LoadingService, academicPeriodService: AcademicPeriodService) {
    this.menuItems = [];
    this._aS = academicPeriodService; // Asignar el servicio AcademicPeriodService
  }

  /**
   * Add menu items to the array
   * @param items
   */
  addMenu(items: Array<{
    text: string,
    heading?: boolean,
    link?: string,     // internal route links
    elink?: string,    // used only for external links
    target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
    icon?: string,
    alert?: string,
    submenu?: Array<any>
  }>) {
    items.forEach((item) => {
      this.menuItems.push(item);
    });
  }

  /**
   * Get all menus
   */
  getMenu() {
    this.loadingService.showLoading(); // Mostrar estado de carga al empezar a cargar el menú
    console.log('Inicio Loading'); // Mostrar mensaje en la consola
    const user: User = JSON.parse(sessionStorage.getItem('userInformation'));
    if (user) {
      const listMenus = (JSON.parse(sessionStorage.getItem('menu_submn')));
      this.addMenu(listMenus);
      this.loadingService.hideLoading(); 
      console.log('Fin Loading ');
      return this.menuItems;
     
    }
  }

  /**
   * get the status of the active period of the active company
   * @param companyId
   */
  async getActivePeriod(companyId) {
    this.loadingService.showLoading(); // Mostrar estado de carga al empezar a cargar el periodo activo
    console.log('Loading shown'); // Mostrar mensaje en la consola
    const result = await this._aS.getCurrentPeriodAcademicStatus(companyId);
    this.loadingService.hideLoading(); // Ocultar estado de carga al finalizar la carga del periodo activo
    console.log('Loading hidden'); // Mostrar mensaje en la consola
    return result;
  }
}