import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loadingStatus = this._loading.asObservable();

  constructor() { }

  showLoading() {
    this._loading.next(true);
  }

  hideLoading() {
    this._loading.next(false);
  }

  // Método para bloquear las interacciones del usuario
  blockUserInteractions() {
    // Agregar un overlay o realizar otras acciones necesarias para bloquear las interacciones
    // Por ejemplo, podrías mostrar un div overlay con posición fija que cubra toda la página
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    overlay.style.zIndex = '9999';
    overlay.id = 'loading-overlay'; // Identificador para poder eliminarlo después

    document.body.appendChild(overlay);
  }

  // Método para desbloquear las interacciones del usuario
  unblockUserInteractions() {
    const overlay = document.getElementById('loading-overlay');
    if (overlay) {
      document.body.removeChild(overlay);
    }
  }
}
