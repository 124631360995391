import {Component, OnInit, ViewChild} from '@angular/core';
import swal from 'sweetalert';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {SendnotificationService} from '../../services/notification/sendnotification.service';
import {SelectionModel} from '@angular/cdk/collections';
import {Notificationroute} from '../class/class.notificationroute';
import {TokenService} from '../../models/services/token.service';
import {UserInformation} from '../../routes/pages/login/class/class.login';

@Component({
  selector: 'app-sendnotificationroute',
  templateUrl: './sendnotificationroute.component.html',
  styleUrls: ['./sendnotificationroute.component.scss']
})
export class SendnotificationrouteComponent implements OnInit {

  /**
   * Variables del formulario de envio de novedades
   * Variables of the news submission form
   *
   * @private
   * @memberof SendnotificationrouteComponent
   */
  private title = '';
  private message = '';
  private input_search = '';

  private usersession: UserInformation
  ;

  /**
   * Variables para tabla
   * Table variables
   *
   * @private
   * @memberof SendnotificationrouteComponent
   */
  private displayedColumns = new Array();
  private dataSource;
  private selection;

  @ViewChild(MatPaginator, {}) paginator: MatPaginator;

  constructor(
      private sendnotification: SendnotificationService,
      private tokenService: TokenService
  ) {
  }

  /**
   * Se ejecuta al cargar el componente
   * It is executed when the component is loaded
   *
   * @memberof SendnotificationrouteComponent
   */
  ngOnInit() {

    this.usersession = this.tokenService.USER;
    this.get_all_data();

  }

  /**
   * Filtro para la tabla
   * Table filter
   *
   * @param {string} filterValue
   * @memberof SendnotificationrouteComponent
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Carga la data en la tabla
   * Load the data into the table
   *
   * @param {*} data
   * @memberof SendnotificationrouteComponent
   */
  load_data_table_pagination(data) {

    this.displayedColumns = ['select', 'nombreRuta', 'nombreConductor'];
    this.dataSource = new MatTableDataSource<Notificationroute>(data);
    this.dataSource.paginator = this.paginator;
    this.selection = new SelectionModel<Notificationroute>(true, []);

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Notificationroute): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.codigoRuta + 1}`;
  }

  /**
   * Obtiene la data de los representantes
   * Get the data of the representatives
   *
   * @memberof SendnotificationrouteComponent
   */
  get_all_data() {

    document.getElementById('cover').style.display = 'block';

    this.sendnotification.get_data_routes_by_idcompany().subscribe(
        response => {
          //console.log(response);
          if (response.body.statusCode == 200) {
            this.load_data_table_pagination(response.body.result);
          }
          document.getElementById('cover').style.display = 'none';
        },
        error => {

          this.fun_load_swal('Aviso', 'Ha ocurrido un error con el servidor.', 'error');
          document.getElementById('cover').style.display = 'none';

        }
    );

  }

  /**
   * Escribe la notificacion en firebase
   * Write the notification in firebase
   *
   * @memberof SendnotificationrouteComponent
   */
  send_notification() {
    document.getElementById('cover').style.display = 'block';
    //console.log(this.selection.selected);
    if (this.selection.selected.length > 0) {

      // var dato = this.get_date()+"_admin_"+this.usersession.id;

      for (let i = 0; i < this.selection.selected.length; i++) {

        for (let r = 0; r < this.selection.selected[i].driverNotificationRouteDTOList.length; r++) {

          for (let y = 0; y < this.selection.selected[i].advisorNotificationRouteDTOS.length; y++) {

            for (let t = 0; t < this.selection.selected[i].advisorNotificationRouteDTOS[y].passengerNotificationRouteDTOS.length; t++) {
              var dato = this.get_date() + '_conductor_' + this.selection.selected[i].driverNotificationRouteDTOList[r].idConductor;
              this.save_notification_firebase(
                  this.selection.selected[i].advisorNotificationRouteDTOS[y].passengerNotificationRouteDTOS[t].idPasajero,
                  this.selection.selected[i].advisorNotificationRouteDTOS[y].passengerNotificationRouteDTOS[t].nombresPasajero,
                  dato
              );

            }
          }

        }

      }
      document.getElementById('cover').style.display = 'none';
      this.reset_values();
      this.fun_load_swal('Aviso', 'Se han enviado correctamente.', 'success');
    } else {
      this.fun_load_swal('Aviso', 'Debe seleccionar al menos un conductor.', 'info');
    }

  }

  save_notification_firebase(idpassenger, namepassenger, date) {

    var data = {
      dia: this.get_date(),
      envia: 'admin_' + this.usersession.selectedCompany.id,
      enviado: false,
      fecha: this.get_date() + ':' + this.get_time(),
      hora: this.get_time(),
      idPasajero: idpassenger,
      leidoAppAdmin: false,
      leidoAppPadres: false,
      leidoWeb: false,
      mensaje: this.message,
      nombreConductor: this.usersession.name,
      nombrePasajero: namepassenger,
      recibe: 'alumno_' + idpassenger,
      titulo: this.title
    };

    this.sendnotification.add_data_novedades_invoice(data, date).subscribe(
        response => {

        },
        error => {

        }
    );

  }

  /**
   * Resetea los valores del formulario para el envio de una nueva notificacion
   * Reset the form values for sending a new notification
   *
   * @memberof SendnotificationrouteComponent
   */
  reset_values() {
    this.selection.clear();
    this.title = '';
    this.message = '';
    this.input_search = '';
    this.applyFilter(this.input_search);
  }

  /**
   * Obtiene la fecha actual
   * Get the current date
   *
   * @returns
   * @memberof SendnotificationrouteComponent
   */
  get_date() {
    var date = new Date();

    var year = date.getFullYear();
    var mounth = date.getMonth() + 1;
    var day = date.getDate();

    return this.add_zero_date(day) + '-' + this.add_zero_date(mounth) + '-' + year;

  }

  /**
   * Obtiene la hora actual
   * Get the current time
   *
   * @returns
   * @memberof SendnotificationrouteComponent
   */
  get_time() {

    var date = new Date();

    var hour = date.getHours();
    var min = date.getMinutes();
    var sec = date.getSeconds();

    return this.add_zero_date(hour) + ':' + this.add_zero_date(min) + ':' + this.add_zero_date(sec);

  }

  /**
   * Agrega el 0 a cada valor de la hora o fecha
   * Add 0 to each time or date value
   *
   * @param {*} data
   * @returns
   * @memberof SendnotificationrouteComponent
   */
  add_zero_date(data) {
    return (data < 10) ? '0' + data.toString() : data;
  }

  //SWAL PARA MOSTRAR ERRORES O VALIDACIONES DE ENVIO DE DATOS A LOS SERVICIOS
  fun_load_swal(title, text, type) {
    swal({
      title: title,
      text: text,
      type: type,
      showCancelButton: false,
      confirmButtonColor: '#8edbff',
      confirmButtonText: 'Aceptar',
      //cancelButtonText: 'Cancelar',
      closeOnConfirm: true,
      closeOnCancel: true
    }, (isConfirm) => {

    });
  }


}
