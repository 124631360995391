import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {AngularFirestore} from 'angularfire2/firestore';
import {TokenService} from '../../../models/services/token.service';


@Injectable({
  providedIn: 'root'
})
export class ReportUseServiceService {
  city;
  response;
  private loggedUser;
  configUrl = environment.urlServer + 'reportController/';

  constructor(private http: HttpClient, private firestore: AngularFirestore, private tokenService: TokenService) {
    this.loggedUser = this.tokenService.USER;
    this.city = this.loggedUser.selectedCompany.city.name;
  }
  getDataResumen(id: number): Observable<HttpResponse<any>> {
    console.log(this.configUrl + 'routeResume/' + id);
    return this.http.get<any>(
        this.configUrl + 'routeResume/' + id, {observe: 'response'});
  }
  getDataReportAttendance(idHistory: number, idPath: number): Observable<HttpResponse<any>> {
    return this.http.get<any>(
        this.configUrl + 'v1.0/use/attendanceV1/' + idPath + '/' + idHistory,
        {observe: 'response'});
  }

  get_date_by_path(path) {
    return this.firestore
        .collection(this.city)
        .doc('historial_' + this.city)
        .collection('historial')
        .doc(path.toString())
        .collection('fechas')
        .snapshotChanges();
  }

  get_points_by_path(path, date) {
    return this.firestore
        .collection(this.city)
        .doc('historial_' + this.city)
        .collection('historial')
        .doc(path.toString())
        .collection('fechas')
        .doc(date)
        .collection('puntos')
        .snapshotChanges();
  }
  getReportUseDate(dateI: number, dateF: number, idCompany: any, typePath: number): Observable<HttpResponse<any>> {
    return this.http.get<any>(
        environment.urlServer + 'report/v1.0/getReportByDays/'
        + dateI + '/' + dateF + '/' + idCompany + '/' + typePath, {observe: 'response'});
  }
  get_driver_route(
      idcompany: number,
      date: String,
      timezone: any
  ): Observable<HttpResponse<any>> {
    return this.http
        .get<any>(
            environment.urlServer + 'report/v1.0/use/day/' + idcompany + '/' + date + '/' + timezone,
            {observe: 'response'}
        );
  }

}
