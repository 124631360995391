import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {PassengerEnrollmentStatus} from '../../routes/administrate/passenger-status-class/enum/passengerEnrollmentStatus';
import {Responseservice} from '../../routes/school/class/class.responseservice';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
    })
};

@Injectable({
  providedIn: 'root'
})
export class InscriptionsService {

  constructor(
      private http: HttpClient
  ) { }

  urlService = environment.urlServer;
   /**Listado de Todos los alumnos registrados
    * List all students registered**/
  get_all_students(
      idCompany: number
  ): Observable<HttpResponse<any>> {
    return this.http
        .get<any>(
            this.urlService + 'inscriptionsServices/v2.0/getAllActivePassengers/' + idCompany,
            {observe: 'response'}
        );
  }
  /** Listado alumnos aprobados
   * List students accepted**/
  get_students_active(
      idcompany: number
  ): Observable<HttpResponse<any>> {
      return this.http
          .get<any>(
              this.urlService + 'inscriptionsServices/v2.0/passengersByStatus/' +
              idcompany + '/' + PassengerEnrollmentStatus.ACCEPTED + '',
              {observe: 'response'}
          );
  }

  /**Listado alumnos registrados
   * List student Registered**/

    get_students_to_activate(
        idcompany: number
    ): Observable<HttpResponse<any>> {
        return this.http
            .get<any>(
                this.urlService + 'inscriptionsServices/v2.0/passengersByStatus/' +
                idcompany + '/' + PassengerEnrollmentStatus.REGISTERED + '',
                {observe: 'response'}
            );
    }


    set_student_status1(
        data: any,
        status: PassengerEnrollmentStatus
    ): Observable<Responseservice> {
        return this.http
            .post<any>(
                this.urlService + 'inscripciones/v2.0/changeInscState/' + status, data,
                httpOptions
            );
    }

    // get_data_company(
    //     idcompany: string
    // ): Observable<HttpResponse<any>> {
    //     return this.http
    //         .get<any>(
    //             this.urlService + 'inscriptionsServices/v1.0/findContract/' + idcompany,
    //             {observe: 'response'}
    //         );
    // }

}
