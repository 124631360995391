import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, QueryFn } from 'angularfire2/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpResponse, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { User } from '../../routes/administrate/class/class.user';
import {TokenService} from '../../models/services/token.service';
@Injectable({
  providedIn: 'root'
})
export class SendnotificationService {

  urlService = environment.urlServer;
  private user;
  private city;
  private namecompany;

  constructor(
    private db : AngularFirestore,
    private authdirebase : AngularFireAuth,
    private http: HttpClient,
    private token: TokenService
  ) { 

    this.user = this.token.USER;
    this.city = this.user.selectedCompany.city.name;
    this.namecompany = this.user.selectedCompany.companyName;

  }

  get_data_invoice_by_idcompany(): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        this.urlService + "notificaciones/v1.0/listaEstudiantes/"+this.user.selectedCompany.id,
        { observe: "response" }
      )
  }

  get_data_drivers_by_idcompany(): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        this.urlService + "notificaciones/v1.0/getDriverNotificationList/"+this.user.selectedCompany.id,
        { observe: "response" }
      )
  }

  get_data_routes_by_idcompany(): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        this.urlService + "notificaciones/v1.0/getPassengerAdvisorRouteList/"+this.user.selectedCompany.id,
        { observe: "response" }
      )
  }

  get_data_level(
    idcompany : string
  ): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        this.urlService + "notificaciones/v1.0/getParallelByCompanyId/"+idcompany,
        { observe: "response" }
      );
  }

  get_data_product(
    idcompany : string,
    idlevel : any,
    idroute : any
  ): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        this.urlService + "notificaciones/v1.0/getCarTypesByCOmpanyIdandLevelRouteId/"+idcompany+"/"+idlevel+"/"+idroute,
        { observe: "response" }
      );
  }

  get_data_invoice_with_filters(
    idcompany : string,
    idlevel : any,
    idroute : any,
    idtypeproduct : any
  ): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        this.urlService + "notificaciones/v1.0/getNotificationList/"+idcompany+"/"+idlevel+"/"+idroute+"/"+idtypeproduct,
        { observe: "response" }
      );
  }

  get_route(
    idcompany : number,
    idlevel : any
  ): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        this.urlService + "notificaciones/v1.0/getRoutesByCompanyIdandlevelID/"+idcompany+"/"+idlevel,
        { observe: "response" }
      );
  }

  add_data_novedades_invoice(
    data : any,
    campo : string
  ): Observable<any>{
    return new Observable((observer) => {

        this.db.collection(this.city.toString()).doc('novedades').collection(this.namecompany.toString()).doc('representantes').collection(campo).add(data).then(
          (response) => {
            observer.next({
              estado : true
            });
          }
        ).catch((error) => {
          observer.next({
            estado : false
          });
          
        })
  
      }
    )
    
  }

  add_data_novedades_drivers(
    data : any,
    campo : string
  ): Observable<any>{
    return new Observable((observer) => {

        this.db.collection(this.city.toString()).doc('novedades').collection(this.namecompany.toString()).doc('conductor').collection(campo).add(data).then(
          (response) => {
            observer.next({
              estado : true
            });
          }
        ).catch((error) => {
          observer.next({
            estado : false
          });
          
        })
  
      }
    )
    
  }
  
  getNotificationsSentAdvisor(date: string) {
    const year = Number(date.split("-")[2]);
    const month = Number(date.split("-")[1]);
    const lastDay = new Date(year, month, 0);
    let documentsArray: Promise<firebase.firestore.QuerySnapshot>[] = [];
    for (let i = 0; i < lastDay.getDate(); i++) {
      let dateConsult = `${i+1}`.toString().length > 1 ? `${i+1}-` : `0${i+1}-`;
      dateConsult += month > 9 ? month : `0${month}`+"-"+year;
      const document = this.db
        .collection(this.city)
        .doc("novedades")
        .collection(this.namecompany)
        .doc("representantes")
        .collection(`${dateConsult}_conductor_${this.user.selectedCompany.id}`)
        .ref.orderBy("fecha", "asc")
        .get();
      documentsArray.push(document);
    }
    return documentsArray;
  }

  getNotificationsSentRoute(date: string): Observable<Promise<firebase.firestore.QuerySnapshot>[]> {
    const year = Number(date.split("-")[2]);
    const month = Number(date.split("-")[1]);
    const lastDay = new Date(year, month, 0);
    let documentsArray: Promise<firebase.firestore.QuerySnapshot>[] = [];
    const idsDocumentsSaved: String[] = [];
    var subject = new Subject<Promise<firebase.firestore.QuerySnapshot>[]>();
    this.get_data_routes_by_idcompany().subscribe(
      (response) => {
        if (response.body.statusCode == 200) {
          const dataRoutes: any[] = response.body.result;
          dataRoutes.forEach((route) => {
            route.driverNotificationRouteDTOList.forEach((driver) => {
              for (let i = 0; i < lastDay.getDate(); i++) {
                let dateConsult = `${i + 1}`.toString().length > 1 ? `${i + 1}-` : `0${i + 1}-`;
                dateConsult += month > 9 ? month : `0${month}` + "-" + year;
                const collection = `${dateConsult}_conductor_${driver.idConductor}`;
                const document = this.db
                  .collection(this.city)
                  .doc("novedades")
                  .collection(this.namecompany)
                  .doc("representantes")
                  .collection(collection)
                  .ref.where(
                    "envia",
                    "==",
                    `admin_${this.user.selectedCompany.id}`
                  )
                  .get();
                if (idsDocumentsSaved.find((id) => id == collection) == undefined) {
                  idsDocumentsSaved.push(collection);
                  documentsArray.push(document);
                };
              }
            });
          });
          subject.next(documentsArray);
        }
      },
      (error) => {}
    );
    return subject.asObservable();
  }

  getNotificationsSentDriver(date: string) {
    const year = Number(date.split("-")[2]);
    const month = Number(date.split("-")[1]);
    const lastDay = new Date(year, month, 0);
    let documentsArray: Promise<firebase.firestore.QuerySnapshot>[] = [];
    for (let i = 0; i < lastDay.getDate(); i++) {
      let dateConsult = `${i+1}`.toString().length > 1 ? `${i+1}-` : `0${i+1}-`;
      dateConsult += month > 9 ? month : `0${month}`+"-"+year;
      const document = this.db
        .collection(this.city)
        .doc("novedades")
        .collection(this.namecompany)
        .doc("conductor")
        .collection(`${dateConsult}`)
        .ref.orderBy("fecha", "asc")
        .get();
      documentsArray.push(document);
    }
    return documentsArray;
  }
}
