// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// import { Level } from "../app/routes/administrate/class/class.level";
import { ClassSchedule } from '../app/routes/administrate/class/class.company';
import { Level } from '../app/routes/administrate/class/class.level';

const secret = 'AIzaSyBCfJ9dFaiv94EWvv8c9tDBnwTF9RY9D6g';
const domain = 'mi-buseta-357902.firebaseapp.com';

export let environment: {
  emailFirebase: string;
  urlMicroServices: string;
  apikey: string;
  // urlMicroserviceEmail: string;
  production: boolean;
  urlServer: string;
  advisorMicroservices: string;
  passwordFirebase: string;
  firebase: {
    storageBucket: string;
    apiKey: string;
    messagingSenderId: string;
    appId: string;
    projectId: string;
    measurementId: string;
    databaseURL: string;
    authDomain: string
  };
  urlDocServer: string;
  urlMicroServicesClient: string;
  urlMicroServicesCobrosv2: string;
  urlMicroServicesFactura: string
};
environment = {
  production: false,
  // urlServer: 'http://192.168.1.33:8080/mibusetabackend/rest/', // servidor local
  // urlMicroServicesFactura: 'http://192.168.1.24:8082/api/',
  // tslint:disable-next-line:comment-format
  //urlMicroServicesCobros:'http://192.168.1.27:8082/',

  urlDocServer: 'https://mibusetabackend.com:8443/feDocs',
  urlServer: 'https://mibusetabackend.com:8443/MiBusetaBackend/rest/', // servidor producción
  urlMicroServicesCobrosv2: 'https://mibusetabackend.com:8091/cobrosService/',
  urlMicroServicesFactura: 'https://mibusetabackend.com:8085/api/',
  urlMicroServicesClient: 'https://mibusetabackend.com:8091/externalClient',
  urlMicroServices: 'https://mibusetabackend.com:8091',
  // urlMicroServices: 'http://192.168.1.33:8091',//localhost ofc
  // urlMicroServices: 'http://192.168.18.191:8091',//localhost home
  advisorMicroservices: 'https://513b2fe4be31:33489',
  apikey: 'AIzaSyBCfJ9dFaiv94EWvv8c9tDBnwTF9RY9D6g',
  firebase: {
    apiKey: `${secret}`,
    authDomain: `${domain}`,
    databaseURL: 'https://mi-buseta-357902.firebaseio.com',
    projectId: 'mi-buseta-357902',
    storageBucket: 'mi-buseta-357902.appspot.com',
    messagingSenderId: '515892819518',
    appId: '1:515892819518:web:06943b0b7f075f48ba036f',
    measurementId: 'G-NWSJMMWNSG',
  },
  emailFirebase: 'productionReadOnly@webNew.com',
  passwordFirebase: 'ususariosololectura', // LjZzuSmHGcQCfnGTL27Jeshddgm2
};

export const passengerLevels: Level[] = [
  {
    id: 0,
    name: 'Inicial Uno',
    state: 1,
  },
  {
    id: 1,
    name: 'Inicial Dos',
    state: 1,
  },
  {
    id: 2,
    name: 'Primero de básica',
    state: 1,
  },
  {
    id: 3,
    name: 'Segundo de básica',
    state: 1,
  },
  {
    id: 4,
    name: 'Tercero de básica',
    state: 1,
  },
  {
    id: 5,
    name: 'Cuarto de básica',
    state: 1,
  },
  {
    id: 6,
    name: 'Quinto de básica',
    state: 1,
  },
  {
    id: 7,
    name: 'Sexto de básica',
    state: 1,
  },
  {
    id: 8,
    name: 'Septimo de básica',
    state: 1,
  },
  {
    id: 9,
    name: 'Octavo de básica',
    state: 1,
  },
  {
    id: 10,
    name: 'Noveno de básica',
    state: 1,
  },
  {
    id: 11,
    name: 'Decimo de básica',
    state: 1,
  },
  {
    id: 12,
    name: 'Primero de bachillerato',
    state: 1,
  },
  {
    id: 13,
    name: 'Segundo de bachillerato',
    state: 1,
  },
  {
    id: 14,
    name: 'Tercero de bachillerato',
    state: 1,
  },
];

export const closing_hour_company: ClassSchedule[] = [
  {
    id: 0,
    description: 'Horario Uno',
  },
  {
    id: 1,
    description: 'Horario Dos',
  },
  {
    id: 2,
    description: 'Horario Tres',
  },
];
export const key_jwt =
    'jwtSecretKeyTOKEN=RXF1aXZvY2Fyc2UgZXMgdW5hIG9wY2lvbiBzaSBsYXMgY29zYXMgbm8gZXN0YW4gZmFsbGFuZG8gbm8gZXN0YXMgaW5ub3ZhbmRvIGxvIHN1ZmljaWVudGU=jwtSecretKeyPassword=bnVuY2EgbXVlcmUgbGEgbHV6IGRlIGxhIGlkZWE=';

export const timeout_services = 500000;
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
