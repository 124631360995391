import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

declare var $: any;

import { MenuService } from '../../core/menu/menu.service';
import { SettingsService } from '../../core/settings/settings.service';
import { InscriptionsService } from '../../services/incriptions/inscriptions.service';
import { TokenService } from '../../models/services/token.service';
import { User } from '../../routes/administrate/class/class.user';
import { NewsService } from '../../services/notification/news.service';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  menuItems: Array<any>;
  router: Router;
  sbclickEvent = 'click.sidebar-toggle';
  $doc: any = null;
  toActivateCount;
  private userSession;
  loadingSubscription: Subscription;
  isLoading: boolean = false;

  constructor(
    public menu: MenuService,
    public settings: SettingsService,
    public injector: Injector,
    private token: TokenService,
    private newService: NewsService,
    private serviceInscriptions: InscriptionsService,
    private loadingService: LoadingService
  ) {
    const user: User = JSON.parse(sessionStorage.getItem('userInformation'));
    this.menuItems = menu.getMenu();
    this.menuItems = this.RemoveSubmenu(this.menuItems, 'Reasignación de rutas');
    this.menuItems = this.RemoveSubmenu(this.menuItems, 'Pagos');
    this.menuItems = this.RemoveSubmenu(this.menuItems, 'Generar Deuda');
    if (user.selectedCompany.rol.id != 1) {
      this.menuItems = this.RemoveSubmenu(this.menuItems, 'Período Lectivo');
      this.menuItems = this.moveMenu(this.menuItems, "Unidades Educativas", "Mi Cuenta")
      this.menuItems = this.renameMenu(this.menuItems, 'Unidades Educativas ', 'Cuenta');
    }
    if (user.generalRol == 0) {
      this.menuItems = this.RemoveSubmenu(this.menuItems, 'Publicidad');
    }
    this.newService.getCompanyActiveModule(user.selectedCompany.id, 'facturation').subscribe( 
      (response: any) => {
        if (response != undefined && response != null) {
          if (!response.status) {
            this.menuItems = this.RemoveSubmenu(this.menuItems, 'Facturación');
            this.menuItems = this.RemoveSubmenu(this.menuItems, 'Configuración Tributaria');
          }
        } else {
          this.menuItems = this.RemoveSubmenu(this.menuItems, 'Facturación');
          this.menuItems = this.RemoveSubmenu(this.menuItems, 'Configuración Tributaria');
        }
      }
    )
  }

  ngOnInit() {
    this.userSession = this.token.USER;
    this.router = this.injector.get(Router);
    this.router.events.subscribe(() => {
      this.removeFloatingNav();
      window.scrollTo(0, 0);
      this.settings.setLayoutSetting('asideToggled', false);
    });
    this.anyClickClose();
    this.count_to_activate_studentes();
    this.loadingSubscription = this.loadingService.loadingStatus.subscribe(isLoading => {
      this.isLoading = isLoading;
      if (isLoading) {
        this.blockUserInteractions();
        console.log('Loading activado');
      } else {
        this.unblockUserInteractions();
        console.log('Loading desactivado');
      }
    });
  }

  ngOnDestroy() {
    if (this.$doc) {
      this.$doc.off(this.sbclickEvent);
    }
    this.loadingSubscription.unsubscribe();
  }

  blockUserInteractions() {
    // Desactivar el listener para clics en la barra lateral durante la carga
    this.$doc.off(this.sbclickEvent);
  }

  unblockUserInteractions() {
    // Volver a activar el listener para clics en la barra lateral después de la carga
    this.anyClickClose();
  }

  anyClickClose() {

    this.$doc = $(document).on(this.sbclickEvent, (e) => {
      if (!$(e.target).parents('.aside-container').length) {
        this.settings.setLayoutSetting('asideToggled', false);
      }
    });
  }

  count_to_activate_studentes() {
    this.serviceInscriptions.get_students_to_activate(this.userSession.selectedCompany.id).subscribe(response => {
      this.toActivateCount = response.body.result.length;
    });
  }

  toggleSubmenuClick(event) {
    this.loadingService.showLoading();
    event.preventDefault();
    if (!this.isSidebarCollapsed() && !this.isSidebarCollapsedText() && !this.isEnabledHover()) {

      const target = $(event.target || event.srcElement || event.currentTarget);
      let ul, anchor = target;

      if (!target.is('a')) {
        anchor = target.parent('a').first();
      }
      ul = anchor.next();

      const parentNav = ul.parents('.sidebar-subnav');
      $('.sidebar-subnav').each((idx, el) => {
        const $el = $(el);
        if (!$el.is(parentNav) && !$el.is(ul)) {
          this.closeMenu($el);
        }
      });

      if (!ul.length) {
        this.loadingService.hideLoading();
        return;
      }

      ul.find('.sidebar-subnav').each((idx, el) => {
        this.closeMenu($(el));
      });

      if (parseInt(ul.height(), 0)) {
        this.closeMenu(ul);
      } else {
        ul.on('transitionend', () => {
          ul.height('auto').off('transitionend');
        }).height(ul[0].scrollHeight);
        ul.addClass('opening');
      }
    }
    this.loadingService.hideLoading();
  }

  closeMenu(elem) {
    elem.height(elem[0].scrollHeight);
    elem.height(0);
    elem.removeClass('opening');
  }

  toggleSubmenuHover(event) {
    // console.log('***SUBMENU any HOVER****');
    const self = this;
    if (this.isSidebarCollapsed() || this.isSidebarCollapsedText() || this.isEnabledHover()) {
      event.preventDefault();

      this.removeFloatingNav();

      const target = $(event.target || event.srcElement || event.currentTarget);
      let ul, anchor = target;
      // find the UL
      if (!target.is('a')) {
        anchor = target.parent('a');
      }
      ul = anchor.next();

      if (!ul.length) {
        return; // if not submenu return
      }
      const $aside = $('.aside-container');
      const $asideInner = $aside.children('.aside-inner'); // for top offset calculation
      const $sidebar = $asideInner.children('.sidebar');
      const mar = parseInt($asideInner.css('padding-top'), 0) + parseInt($aside.css('padding-top'), 0);
      const itemTop = ((anchor.parent().position().top) + mar) - $sidebar.scrollTop();

      const floatingNav = ul.clone().appendTo($aside);
      const vwHeight = $(window).height();

      // let itemTop = anchor.position().top || anchor.offset().top;
      floatingNav
          .removeClass('opening') // necesary for demo if switched between normal//collapsed mode
          .addClass('nav-floating')
          .css({
            position: this.settings.getLayoutSetting('isFixed') ? 'fixed' : 'absolute',
            top: itemTop,
            bottom: (floatingNav.outerHeight(true) + itemTop > vwHeight) ? 0 : 'auto'
          });
      floatingNav
          .on('mouseleave', () => {
            floatingNav.remove();
          })
          .find('a').on('click', async function (e) {
        e.preventDefault(); // prevents page reload on click
        // get the exact route path to navigate
        console.log("******** HACE UN CLICK ****");
        const routeTo = $(this).attr('route');
        if (routeTo) {
          await self.router.navigate([routeTo]);
        }
      });

      this.listenForExternalClicks();

    }

  }

  listenForExternalClicks() {
    const $doc = $(document).on('click.sidebar', (e) => {
      if (!$(e.target).parents('.aside-container').length) {
        this.removeFloatingNav();
        $doc.off('click.sidebar');
      }
    });
  }

  removeFloatingNav() {
    $('.nav-floating').remove();
  }

  isSidebarCollapsed() {
    return this.settings.getLayoutSetting('isCollapsed');
  }

  isSidebarCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  isEnabledHover() {
    return this.settings.getLayoutSetting('asideHover');
  }

  RemoveSubmenu(arreglo: any[], valor: any, nuevaPosicion: any = null): any[] {
    for (let i = 0; i < arreglo.length; i++) {
      const elemento = arreglo[i];
      
      if (elemento.text === valor) {
        arreglo.splice(i, 1); // Elimina el elemento si se encuentra
        i--; // Ajusta el índice para evitar saltar elementos
        
        if (nuevaPosicion !== null) {
          const destino = arreglo.findIndex(submenu => submenu.text === nuevaPosicion);
          
          if (destino !== -1) {
            if (!arreglo[destino].submenu) {
              arreglo[destino].submenu = [];
            }
            arreglo[destino].submenu.push(elemento);
            break;
          }
        }
        
      } else if (elemento.submenu) {
       
        this.RemoveSubmenu(elemento.submenu, valor, nuevaPosicion); // Llamada recursiva para arrays anidados
      }
    }
    
    return arreglo;
  }

  moveMenu(arreglo: any[], initPosition: any, newPosition: any, menuMove: any = null) {
      for (let i = 0; i < arreglo.length; i++) {
        const element = arreglo[i];
        if (element.text == initPosition && menuMove == null) {
          const destino = arreglo.findIndex(
            (submenu) => submenu.text === initPosition
          );
          if (destino != -1) {
            arreglo.splice(i, 1); // Elimina el elemento si se encuentra
            i--;
            this.moveMenu(this.menuItems, initPosition, newPosition, element);
          }
        } else if (menuMove != null) {
          // break;
          if (element.text == newPosition) {
            if (element.submenu) {
              const textValueMenu = menuMove.text + " ";
              element.submenu.push({ id: menuMove.id, text: textValueMenu, link: menuMove.link, submenu: menuMove.submenu });
              break;
            }
          }
        } else if (element.submenu) {
          this.moveMenu(element.submenu, initPosition, newPosition, menuMove); // Llamada recursiva para arrays anidados
        }
    }

    return arreglo;
  }

  renameMenu(menuArray:any[], menuName: string, newName: string) {
    let menus = menuArray;
    for (let i = 0; i < menus.length; i++) {
      const element = menus[i];
      
      if (element.text == menuName) {
        const destino = menuArray.findIndex(submenu => submenu.text === menuName);
        menuArray[destino].text = newName;
        break;
      } else {
        if (element.submenu) {
          if (element.submenu.findIndex(submenu => submenu.text === menuName) != -1)
            this.renameMenu(element.submenu, menuName, newName);

        }
      }
    }
    return menuArray;
  }
}
