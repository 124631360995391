import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireAuth } from 'angularfire2/auth';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { User } from '../../routes/administrate/class/class.user';
import { Observable } from 'rxjs';
import { log } from 'util';
import { Asistenciapassenger } from '../../layout/class/class.asistenciapassenger';
import {UserInformation} from '../../routes/pages/login/class/class.login';
import {TokenService} from '../../models/services/token.service';

@Injectable({
  providedIn: 'root'
})
export class NotshippedService {

  urlService = environment.urlServer;
  private user;
  private city;

  constructor(
    private db: AngularFirestore,
    private authdirebase: AngularFireAuth,
    private http: HttpClient,
    private token: TokenService
  ) { 
    this.user = this.token.USER;
    this.city = this.user.selectedCompany.city.name;
  }

  get_data_date(path : string, date : string) : Observable<any>{

    return new Observable((observer) => {
      
    this.db.collection(this.city.toString()).doc('historial_'+this.city.toString()).collection('historial').doc(path).collection('fechas').snapshotChanges().subscribe(
      response => {
        //console.log(this.get_date_current());
        
        if(response.length > 0){
          var data_result = new Array();
          for(let i = 0; i < response.length; i++){
            //console.log("Fecha actual:" + this.get_date_current() + " id: " + response[i].payload.doc.id.substring(0,10)+ " path"+path);
            if(date == response[i].payload.doc.id.substring(0,10)){
              //console.log("Fecha actual:" + this.get_date_current() + " id: " + response[i].payload.doc.id.substring(0,10)+ " path"+path);
              data_result.push({
                date : response[i].payload.doc.id
              });
            }
            
          }
          return observer.next(data_result);
         
        }

      },
      error => {

      }
    );
    
    })
  }

  get_passengers_not_shipped(path,date) : Observable<any>{

    return this.db.collection(this.city.toString()).doc('historial_'+this.city.toString()).collection('historial').doc(path).collection('fechas').doc(date.toString()).collection('asistencia', ref => ref.where('state', '==', 0)).snapshotChanges();
    
  }

  add_zero_date(data){
    return (data < 10) ? "0" + data.toString() : data;
  }

  get_date_current(){
    var date = new Date();
    var day = date.getUTCDate();
    var month = date.getUTCMonth()+1;
    var year = date.getUTCFullYear();

    return this.add_zero_date(day)+"-"+this.add_zero_date(month)+"-"+year;
  }
}
