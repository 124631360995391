import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  urlMenubyRolID = environment.urlServer + 'authentication/getMenus/';
  urlUpdateSelectedCompany = environment.urlServer + 'user/v1.0/selectedCompany/';

  constructor(private http: HttpClient) { }

  // ------------------- GET MENU AND SUBMENU LIST ------------
  getrolMenuSubmenu(idRol: number): Observable<HttpResponse<any>> {
    const url = this.urlMenubyRolID + idRol;
    return this.http.get<any>(
        url, {observe: 'response'});
  }
  signup() {
  }

  updateSelectedCompanyByIDandIDCompany(idUser: number, id: number): Observable<HttpResponse<any>> {
    const urlAuxIDuserIDCompany = this.urlUpdateSelectedCompany + idUser;
    return this.http
        .post<any>(
            urlAuxIDuserIDCompany,
            {'id': id}
        );
  }

}

