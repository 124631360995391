import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpResponse, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { User } from '../../routes/administrate/class/class.user';
import { Routeactive } from '../../routes/home/class/class.routeactive';
import { isObject, isUndefined } from 'util';
import {UserInformation} from '../../routes/pages/login/class/class.login';


@Injectable({
  providedIn: 'root'
})
export class HomeService {

  urlService = environment.urlServer;
  private user: UserInformation;
  private city;

  constructor(
    private db: AngularFirestore,
    private authdirebase: AngularFireAuth,
    private http: HttpClient
  ) {
    this.user = JSON.parse(sessionStorage.getItem('userInformation'));
    this.city = this.user.selectedCompany.city.name;
   }

  get_data_routes_by_idcompany(): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        this.urlService + 'init/v1.0/activePaths',
        { observe: 'response' }
      );
  }



  get_data_routes(pathroute, idroute){
    return new Observable((observer) => {

      //this.authdirebase.auth.signInWithEmailAndPassword(environment.emailFirebase,environment.passwordFirebase).then(value => {//login as read only
      // console.log("mi pa", pathroute);
      
        this.db.collection(this.city.toString()).doc('rutas').collection('activas').doc(pathroute.toString()).snapshotChanges().subscribe(
          response => {
            
            var data_object : Routeactive;
            
            data_object = response.payload.data();
            // console.log("rr", data_object);

            var data_send = {};
            
            if(isObject(data_object) && data_object.ubicacion != null){
              
              data_send = {
                estado : data_object.estado,
                presicion : data_object.presicion ,
                latitud : data_object.ubicacion.latitude ,
                longuitud : data_object.ubicacion.longitude ,
                route : pathroute ,
                namedriver : data_object.nombreConductor ,
                nameroute : data_object.nombreRuta ,
                id: idroute,
                date: response.payload.data()['fecha'].toDate()
              }
              
              return observer.next(data_send);

            }
            
          },
          error => {
    
          });
  
      /* }).catch(err => {
         console.log('Something went wrong getting all routes of a school:',err.message);
      });  */

      })
  
  }
}
