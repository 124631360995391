import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { Driver } from '../../routes/administrate/class/class.driver';
import { FilterDriver } from '../../routes/administrate/class/class.filterdriver';
import { catchError, retry, timeout } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { environment, timeout_services } from '../../../environments/environment';
import { LicenseType } from '../../routes/administrate/class/class.licenseype';
import { Responsedatabilldriver } from '../../routes/administrate/class/class.responsedatabilldriver';



//import { HttpService } from '../http/http.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token',
    'Accept': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})


export class DriverService {
  // LIST DRIVERS
  configUrl = environment.urlServer + "driver/v1.0/driversWnv2/";
  // ADD OR UPDATE DRIVER
  configAdd = environment.urlServer + "driver/v1.0/driverWnV2/";
  // UPDATE STATE OF DRIVER
  configChangeStateDriver = environment.urlServer + 'driver/v1.0/driverState/';
  // GET DRIVER BY DNI/CI/RUC
  configDriverDNI = environment.urlServer + "driver/v1.0/driverByCI/";
  // GET LIST OF LICENSES TYPES
  urlLicenseTypes = environment.urlServer + "types/v1.0/LicencesTypes/";



  constructor(private http: HttpClient) {
  }

  get_data_bill_driver(
    iddatabilldriver : number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        environment.urlServer + "driver/v1.0/getBillDriverData/"+iddatabilldriver,
        { observe: "response" }
      );
  }

  save_data_bill_driver(
    data : any,
    iddriver : number
  ): Observable<Responsedatabilldriver> {
    return this.http
      .post<any>(
        environment.urlServer + "driver/v1.0/driver/billData/"+iddriver,
        data
      );
  }
  //-------------- return all drivers -------------------
  //this function is called from component and this returns the data from backend 
  getConfigResponse(): Observable<HttpResponse<Driver[]>> {
    return this.http.get<Driver[]>(
      this.configUrl, { observe: 'response' })
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  //------------ PAGINATE DRIVERS BY CONSORTIUM ID -------------------------
  getForSearchFilterPaginationByConsortiumID(consortiumID, from, amount, filter): Observable<HttpResponse<FilterDriver>> {//
    let configUrlSearch = this.configUrl + "byConsotiumWn2V/" + consortiumID + "?from=" + from + "&amount=" + amount + "&filter=" + filter;
    return this.http.get<FilterDriver>(
      configUrlSearch, { observe: 'response' })
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  //------------------------ PAGINATE ALL DRIVERS ----------
  getForSearchFIlterPagination(from, amount, filter): Observable<HttpResponse<FilterDriver>> {
    let configUrlSearch = this.configUrl + "forSearch?from=" + from + "&amount=" + amount + "&filter=" + filter;

    return this.http.get<FilterDriver>(
      configUrlSearch, { observe: 'response' })
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  // ------------------ GET DRIVER BY ID --------------------
  getByDriverId(id): Observable<HttpResponse<Driver>> {
    let configbyID = this.configAdd + id;

    return this.http.get<Driver>(
      configbyID, { observe: 'response' })
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  get_data_driver_from_retention(
    idcompany : any
  ): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        environment.urlServer+"retServices/v1.0/getDriversByCompanyId/"+idcompany,
        { observe: "response" }
      );
  }

  //function returns a message of error if the backend returns nothing
  private handleError(error: HttpErrorResponse) {
    // console.log("Error");
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  //-----------------------SEND AND ADD A NEW DRIVER TO BACKEND ---------------
  addDriver(driver: Driver): Observable<Driver> {
    return this.http.post<Driver>(this.configAdd, driver)
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }
  //------------------------ UPDATE SERVICES ---------------------//
   //Métdoo cambiado de put a post
  updateDriver(driver: Driver): Observable<Driver> {
    console.log(driver)
    return this.http.post<Driver>(this.configAdd+"update", driver)
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // -------------------- UPDATE ONLY DRIVER STATE ----------------------
   //Métdoo cambiado de put a post
   updateDriverState(iddriver, statedriver): Observable<HttpResponse<any>> {
    // console.log(driver)
    return this.http.get<any>(
      this.configChangeStateDriver+"update/"+iddriver+"/"+statedriver, { observe: 'response' });
  }

  //------------------------ LICENSES LIST SERVICES ---------------------//
  listLicenseType(): Observable<HttpResponse<LicenseType>> {
    return this.http.get<LicenseType>(
      this.urlLicenseTypes, { observe: 'response' })
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }


  //-----------------------RETURN DRIVER BY DNI------------
  getDriverByDNI(dni): Observable<HttpResponse<Driver>> {
    let configbyDNI = this.configDriverDNI + dni;
    return this.http.get<Driver>(
      configbyDNI, { observe: 'response' })
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }


}
