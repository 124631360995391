import { Injectable } from '@angular/core';
import { AcademicSession, ConvertAcademicSession } from '../../layout/class/class.academic.session';

@Injectable({
  providedIn: 'root'
})
export class AcademicSessionService {

  getAcademicPeriod(): AcademicSession {
    let academicSession = new AcademicSession();
    try {
      const sessionData = sessionStorage.getItem('academic_period');
      if (sessionData) {
        academicSession = ConvertAcademicSession.toAcademicSession(sessionData);
      } else {
        console.warn('No academic period found in session storage');
      }
    } catch (error) {
      console.error('Error parsing academic period from session storage', error);
    }

    // Ensure academicSession has all properties initialized
    return {
      academicPeriodID: academicSession.academicPeriodID || 0,
      academicPeriodStatus: academicSession.academicPeriodStatus || false,
      academicPeriodDescription: academicSession.academicPeriodDescription || '',
      currentAcademicPeriodID: academicSession.currentAcademicPeriodID || ''
    };
  }
  
}
