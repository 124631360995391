import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { PassengerPoint } from '../../routes/administrate/class/class.passengerPoint';

import { catchError, retry, timeout } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { environment, timeout_services } from '../../../environments/environment';
import { Pointpassenger } from '../../routes/administrate/class/class.pointpassenger';
import { Responseservice } from '../../routes/school/class/class.responseservice';
import { AngularFirestore } from 'angularfire2/firestore';
import { User } from '../../routes/administrate/class/class.user';
import {TokenService} from '../../models/services/token.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token',
    'Accept': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class PointsService {
  
  configUrl = environment.urlServer +"point/v1.0/pointsByIdPassenger/"; //GET ALL POINTS OF PASSENGER

  urlService = environment.urlServer;

  private user;
  private city = "";
  private namecompany = "";


  constructor(private db : AngularFirestore, 
              private http: HttpClient,
              private token: TokenService
             ) {
              this.user = this.token.USER;
              this.city = this.user.selectedCompany.city.name.toString();
              this.namecompany = this.user.selectedCompany.companyName.toString();
              }

  get_last_tour(path : string): Observable<any>{

    return new Observable((observer) => {
      //console.log(path);
      this.db.collection(this.city).doc("historial_"+this.city).collection("historial").doc(path).collection("fechas", ref => ref.orderBy('date', 'desc').limit(1)).snapshotChanges().subscribe(
        response => {
          //console.log(response);
          let datapoints = new Array();
          if(response.length > 0){
            //console.log(path);
            this.db.collection(this.city).doc("historial_"+this.city).collection("historial").doc(path).collection("fechas").doc(response[0].payload.doc.id.toString()).collection("puntos", ref => ref.orderBy('hora', 'asc')).snapshotChanges().subscribe(
              points => {
                //console.log(points);
                for(let i = 0; i < points.length; i++){
                  var object = {
                    latitude : points[i].payload.doc.data().ubicacion.latitude,
                    longitude : points[i].payload.doc.data().ubicacion.longitude
                  }

                  datapoints.push(object);
                }

                return observer.next(datapoints);
              }
            )
          }
        }
      )
    })

  }



  // GET POINTS OF PASSENGER BY PASSENGER ID
  getPointsOfPassenger(id): Observable<HttpResponse<PassengerPoint>> {
    let auxUrlPointID = this.configUrl + id; //add id to the end of the url
    return this.http.get<PassengerPoint>(
      auxUrlPointID, { observe: 'response' })
      .pipe(
        timeout(timeout_services),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  get_data_request_points_by_idcompany(
    idcompany : any
  ): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        this.urlService + "changePoin/v1.0/requestChangeList/"+idcompany,
        { observe: "response" }
      );
  }

  send_data_approve_points_by_idcompany(
    data: any
  ): Observable<Responseservice> {
    return this.http
      .post<any>(
        this.urlService + "changePoin/v1.0/aprobarCambio",
        data,
        httpOptions
      );
  }

  send_data_disapprove_points_by_idcompany(
    data: any
  ): Observable<Responseservice> {
    return this.http
      .post<any>(
        this.urlService + "changePoin/v1.0/negarPunto",
        data,
        httpOptions
      );
  }

   // GET POINTS OF PASSENGER BY PASSENGER ID
   getPassengerwithpoint(id): Observable<HttpResponse<any>> {
    let auxUrlPointID = this.urlService + "administrarPunto/v1.0/getPasajeroAPDTO/" + id; //add id to the end of the url
    return this.http.get<Pointpassenger>(
      auxUrlPointID, { observe: 'response' });
  }

  create_point(
    idpassenger: any,
    data: string
  ): Observable<Responseservice> {
    return this.http
      .post<any>(
        this.urlService + "administrarPunto/v1.0/point/create/"+idpassenger,
        data,
        httpOptions
      );
  }

  update_point(
    idpassenger: any,
    data: any
  ): Observable<Responseservice> {
    return this.http
      .post<any>(
        this.urlService + "administrarPunto/v1.0/point/update/"+idpassenger,
        data,
        httpOptions
      );
  }

  delete_point(
    idpoint: any,
  ): Observable<Responseservice> {
    return this.http
      .delete<any>(
        environment.urlServer + "point/v1.0/point/"+idpoint,
        httpOptions
      );
  }

    //function returns a message of error if the backend returns nothing
    private handleError(error: HttpErrorResponse) {
      // console.log("Error");
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        // console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        // console.error(
        //   `Backend returned code ${error.status}, ` +
        //   `body was: ${error.error}`);
      }
      // return an observable with a user-facing error message
      return throwError(
        'Something bad happened; please try again later.');
    };


}
