import { Component, OnInit, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges, Input } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { User } from '../../routes/administrate/class/class.user';
import { NewsService } from '../../services/notification/news.service';
import { Newadmin } from '../class/class.newadmin';
import {Howl, Howler} from 'howler';
import {TokenService} from '../../models/services/token.service';


@Component({
  selector: 'app-newsadmin',
  templateUrl: './newsadmin.component.html',
  styleUrls: ['./newsadmin.component.scss']
})
export class NewsadminComponent implements OnInit, OnChanges {

  /**
   * Variables de la tabla de material desing
   * Variables in the material design table
   *
   * @private
   * @memberof NewsadminComponent
   */
  private displayedColumns = new Array();
  private dataSource;

  /**
   * Variable para acceder a la informacion del usuario logeado
   * Variable to access logged-in user information
   *
   * @private
   * @type {User}
   * @memberof NewsadminComponent
   */
  private userSession;

  /**
   * Contador de novedades enviadas al administrador
   * News counter sent to the administrator
   *
   * @memberof NewsadminComponent
   */
  @Output() public count = new EventEmitter<number>();

  @Input() public openNoveltiesModal = new EventEmitter<boolean>();
  @Input() public selectedItem = new EventEmitter<number>();

  /**
   * Paginador de la tabla de material desing
   * Material designation table pager
   *
   * @type {MatPaginator}
   * @memberof NewsadminComponent
   */
  @ViewChild(MatPaginator, {}) paginator: MatPaginator;
  countNovelties: number = 0;

  constructor(
    private newService : NewsService,
    private token: TokenService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.userSession = this.token.USER;
    this.loadDataFirebase();
  }

  ngOnInit() {
    this.userSession = this.token.USER;
    this.loadDataFirebase();
    //this.get_sound();
  }

  /**
   * Carga la data en la tabla y la pagina
   * Load the data in the table and page
   *
   * @param {*} data
   * @memberof NewsadminComponent
   */
  load_data_table_pagination(data){
    this.displayedColumns  = ['nombreConductor', 'dia', 'hora', 'titulo', 'descripcion'];
    this.dataSource = new MatTableDataSource<Newadmin>(data.reverse());
    this.dataSource.paginator = this.paginator;
  }
  
  /**
   * Obtiene la informacion de las novedades desde firebase
   * Get news information from firebase
   *
   * @memberof NewsadminComponent
   */
  loadDataFirebase() {
    this.countNovelties = 0;
    let allNoveltyAdmin = [];
    let noveltyAdminDriver = [];
    let noveltyAdminRoute = [];
    const idCompany   = 'idCompany_' + this.userSession.selectedCompany.id;
    const cityName    = this.userSession.selectedCompany.city.name;
    const companyName = this.userSession.selectedCompany.companyName;
    
    const now = new Date();
    let nowString = now.toISOString();
    let listString = nowString.split('T');
    let formatDateNow =listString[0];
    
    let listFormatDateNow = listString[0].split('-');
    let dateFind = `${listFormatDateNow[2]}-${listFormatDateNow[1]}-${listFormatDateNow[0]}`;
    
    this.newService.getDataNoveltyAdmin(cityName,companyName,dateFind).subscribe(
      response=>{
        this.countNovelties = 0;
        noveltyAdminDriver = [];
        response.forEach(r => {
          
          this.countNovelties += r['leido'] == false ? 1 : 0;
          if (this.openNoveltiesModal && !r['leido'] && Number(this.selectedItem) == 1) {
            this.newService.changeStatusDataNoveltyAdmin(cityName, companyName, dateFind, r['id'])
            this.loadDataFirebase();
          }
          noveltyAdminDriver.push(r);
        });
        noveltyAdminDriver=noveltyAdminDriver.map(n=>{
          return{
            fecha:formatDateNow,
            ruta:'N/I',
            novedad:`${n.titulo}: ${n.descripcion}`, 
            ...n
          }
        })
        allNoveltyAdmin=[...noveltyAdminRoute,...noveltyAdminDriver];
        this.count.emit(this.countNovelties);
        this.load_data_table_pagination(allNoveltyAdmin);
      },
      error=>{}
    );

    /**
     * Este metodo se comento porque pare ya no estar en uso
     */
    // this.newService.get_data_news_admin(idCompany).subscribe(
    //   response => {
    //     noveltyAdminRoute = [];
    //     response.forEach(r => {
    //       this.countNovelties += r['estado'] == "VISTO" ? 1 : 0;
    //       if (this.openNoveltiesModal && r['estado'] && Number(this.selectedItem) == 1) {
    //         console.log("r==",r['estado']);
    //         // this.newservice.change_status_data_news_representatives(response[i]['id'], date);
    //       }
    //       noveltyAdminRoute.push(r);
    //       // if (!r.hasOwnProperty('tipo')) {
    //       //   noveltyAdminRoute.push(r);
    //       // }
    //     });
    //     console.log("coutnw2",this.countNovelties);
        
    //     noveltyAdminRoute =noveltyAdminRoute.map(n=>{
    //       return {
    //         ...n,
    //         fecha:n.fecha.split(' ')[0],
    //       }
    //     });
    //     allNoveltyAdmin=[...noveltyAdminRoute,...noveltyAdminDriver];
    //     this.count.emit(allNoveltyAdmin.length);
    //     this.load_data_table_pagination(allNoveltyAdmin);
    //   },
    //   error => {}
    // );
  }

  /**
   * Obtiene la fecha actual
   * Get the current date
   *
   * @returns
   * @memberof NewsadminComponent
   */
  get_date(){
    var date = new Date();

    var year = date.getFullYear();
    var mounth = date.getMonth()+1;
    var day = date.getDate();

    return this.add_zero_date(day)+"-"+this.add_zero_date(mounth)+"-"+year;

  }

  /**
   * Agrega el cero a cualquier elemento de una fecha o hora
   * Add zero to any element of a date or time
   *
   * @param {*} data
   * @returns
   * @memberof NewsadminComponent
   */
  add_zero_date(data){
    return (data < 10) ? "0" + data.toString() : data;
  }

}

