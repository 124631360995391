import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment, timeout_services} from '../../../environments/environment';

import {catchError, retry, timeout} from 'rxjs/operators';

import {User} from '../../routes/administrate/class/class.user';

import {UserLogin} from '../../routes/administrate/class/class.userLogin';
import {Menu} from '../../routes/administrate/class/class.menu';
import {AcademicPeriodService} from '../academic-period/academic-period.service';
import {TokenService} from '../../models/services/token.service';
import {UpdateToken} from '../../layout/class/updateToken';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  urlService = environment.urlServer + 'user/v1.0/login';
  urlUpdateSelectedCompany = environment.urlServer + 'user/v1.0/selectedCompany/';
  urlMenubyRolID = environment.urlServer + 'rol/v1.0/menuByIdRol/';

  constructor(private http: HttpClient,
              private _aS: AcademicPeriodService,
              private token: TokenService) {
  }


  userLogin(usrLogin: UserLogin): Observable<User> {
    return this.http
        .post<User>(this.urlService, usrLogin);
  }

  // -------------------  UPDATE SELECTED COMPANY IN USER  --------------------
  // updateSelectedCompanyByIDandIDCompany(idUser: number, idCompany: number): Observable<object[]> {
  // 	let urlAuxIDuserIDCompany = this.urlUpdateSelectedCompany + idUser + "/" + idCompany;
  // 	// console.log(urlAuxIDuserIDCompany)
  // 	return this.http.put<object[]>(urlAuxIDuserIDCompany, null)
  // 		.pipe(
  // 			timeout(timeout_services),
  // 			retry(3), // retry a failed request up to 3 times
  // 			catchError(this.handleError)
  // 		);
  // }

  updateSelectedCompanyByIDandIDCompany(idUser: number, id: number): Observable<UpdateToken> {
    const urlAuxIDuserIDCompany = this.urlUpdateSelectedCompany + idUser;
    return this.http
        .post<any>(
            urlAuxIDuserIDCompany,
            {'id': id}
        );
  }


  // ------------------- GET MENU AND SUBMENU LIST ------------
  getrolMenuSubmenu(idRol: number): Observable<HttpResponse<Menu[]>> {
    const url = this.urlMenubyRolID + idRol;
    return this.http.get<Menu[]>(
      url, {
        observe: 'response',
      //   params: {
      //     idCompanyType: idCompanyType.toString()
      // }
    });
  }
  // rolMenuSubmenu(idRol:number): Observable<Menu> {
  // 	return this.http
  // 		.post<Menu>(this.urlService, usrLogin)
  // 		.pipe(catchError(this.handleError));
  // }

  signup() {


  }

  userLogout() {
    let usrLogged: any;
    usrLogged = this.token.USER;
    usrLogged && this.token.USER;
    sessionStorage.getItem('menu_submn') && sessionStorage.removeItem('menu_submn');
    sessionStorage.removeItem('academic_period');
    sessionStorage.removeItem('token');
    return `
			{
				"statusCode": "200",
				"mensaje": "Logged Out",
				"error": null,
				"description": "Usuario ${usrLogged.email} ha salido"	
			}
		`;
  }

  isLogged() {
    let usrLogged: any;
    usrLogged = this.token.USER;
    return usrLogged ? true : false;
  }


  private handleError(error: HttpErrorResponse) {
    // console.log("Error");
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
        'Something bad happened; please try again later.');
  };
}
