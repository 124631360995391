import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LockScreenService {

  constructor() { }

  //-------------------------- BLOCK SCREEN DIV --------------------------------
  fun_lock_screen_while_loading() {
    document.getElementById('cover').style.display = 'block';
  }
  
  //-------------------------- BLOCK SCREEN DIV --------------------------------
  fun_unlock_loading_screen() {
    document.getElementById('cover').style.display = 'none';
  }
}
