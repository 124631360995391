import { Component, OnInit, ViewChild } from "@angular/core";
import { User } from "../../routes/administrate/class/class.user";
import swal from "sweetalert";
import { MatPaginator, MatTableDataSource } from "@angular/material";
import { Notificationinvoice } from "../class/class.notificationinvoice";
import { SelectionModel } from "@angular/cdk/collections";
import { SendnotificationService } from "../../services/notification/sendnotification.service";
import { TokenService } from "../../models/services/token.service";
import { UserInformation } from "../../routes/pages/login/class/class.login";

@Component({
  selector: "app-sendnotificationinvoice",
  templateUrl: "./sendnotificationinvoice.component.html",
  styleUrls: ["./sendnotificationinvoice.component.scss"],
})
export class SendnotificationinvoiceComponent implements OnInit {
  /**
   * Variables del formulario de envio de novedades
   * Variables of the news submission form
   *
   * @private
   * @memberof SendnotificationinvoiceComponent
   */
  private title = "";
  private message = "";
  private input_search = "";

  private usersession: UserInformation;

  /**
   * Variables para tabla
   * Table variables
   *
   * @private
   * @memberof SendnotificationinvoiceComponent
   */
  private displayedColumns = new Array();
  private dataSource;
  private selection;

  @ViewChild(MatPaginator, {}) paginator: MatPaginator;

  constructor(
    private sendnotification: SendnotificationService,
    private tokenService: TokenService
  ) {}

  /**
   * Se ejecuta al cargar el componente
   * It is executed when the component is loaded
   *
   * @memberof SendnotificationinvoiceComponent
   */
  ngOnInit() {
    this.usersession = this.tokenService.USER;
    this.get_all_data();
  }

  /**
   * Filtro para la tabla
   * Table filter
   *
   * @param {string} filterValue
   * @memberof SendnotificationinvoiceComponent
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Carga la data en la tabla
   * Load the data into the table
   *
   * @param {*} data
   * @memberof SendnotificationinvoiceComponent
   */
  load_data_table_pagination(data) {
    this.displayedColumns = [
      "select",
      "nombreEstudiante",
      "nivel",
      "tipoTransporte",
      "nombreRuta",
      "nombreRepresentante",
    ];
    this.dataSource = new MatTableDataSource<Notificationinvoice>(data);
    this.dataSource.paginator = this.paginator;
    this.selection = new SelectionModel<Notificationinvoice>(true, []);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Notificationinvoice): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.codigoPasajero + 1
    }`;
  }

  /**
   * Obtiene la data de los representantes
   * Get the data of the representatives
   *
   * @memberof SendnotificationinvoiceComponent
   */
  get_all_data() {
    document.getElementById("cover").style.display = "block";

    this.sendnotification.get_data_invoice_by_idcompany().subscribe(
      (response) => {
        //console.log(response);
        if (response.body.statusCode == 200) {
          this.load_data_table_pagination(response.body.result);
        }
        document.getElementById("cover").style.display = "none";
      },
      (error) => {
        this.fun_load_swal(
          "Aviso",
          "Ha ocurrido un error con el servidor.",
          "error"
        );
        document.getElementById("cover").style.display = "none";
      }
    );
  }

  /**
   * Escribe la notificacion en firebase
   * Write the notification in firebase
   *
   * @memberof SendnotificationinvoiceComponent
   */
  send_notification() {
    //console.log(this.selection.selected);
    if (this.selection.selected.length > 0) {
      var dato =
        this.get_date() + "_conductor_" + this.usersession.selectedCompany.id;
      for (let i = 0; i < this.selection.selected.length; i++) {
        var data = {
          dia: this.get_date(),
          envia: "admin_" + this.usersession.selectedCompany.id,
          enviado: false,
          fecha: this.get_date() + ":" + this.get_time(),
          hora: this.get_time(),
          idPasajero: this.selection.selected[i].codigoPasajero,
          leidoAppAdmin: false,
          leidoAppPadres: false,
          leidoWeb: false,
          mensaje: this.message,
          nombreConductor: this.usersession.name,
          nombrePasajero: this.selection.selected[i].nombreEstudiante,
          recibe: "alumno_" + this.selection.selected[i].codigoPasajero,
          titulo: this.title,
        };

        this.sendnotification.add_data_novedades_invoice(data, dato).subscribe(
          (response) => {},
          (error) => {}
        );
      }

      this.fun_load_swal("Aviso", "Se ha enviado correctamente.", "success");
      this.reset_values();
    } else {
      this.fun_load_swal(
        "Aviso",
        "Debe seleccionar al menos un alumno.",
        "info"
      );
    }
  }

  /**
   * Resetea los valores del formulario para el envio de una nueva notificacion
   * Reset the form values for sending a new notification
   *
   * @memberof SendnotificationinvoiceComponent
   */
  reset_values() {
    this.selection.clear();
    this.title = "";
    this.message = "";
    this.input_search = "";
    this.applyFilter(this.input_search);
  }

  /**
   * Obtiene la fecha actual
   * Get the current date
   *
   * @returns
   * @memberof SendnotificationinvoiceComponent
   */
  get_date() {
    var date = new Date();

    var year = date.getFullYear();
    var mounth = date.getMonth() + 1;
    var day = date.getDate();

    return (
      this.add_zero_date(day) + "-" + this.add_zero_date(mounth) + "-" + year
    );
  }

  /**
   * Obtiene la hora actual
   * Get the current time
   *
   * @returns
   * @memberof SendnotificationinvoiceComponent
   */
  get_time() {
    var date = new Date();

    var hour = date.getHours();
    var min = date.getMinutes();
    var sec = date.getSeconds();

    return (
      this.add_zero_date(hour) +
      ":" +
      this.add_zero_date(min) +
      ":" +
      this.add_zero_date(sec)
    );
  }

  /**
   * Agrega el 0 a cada valor de la hora o fecha
   * Add 0 to each time or date value
   *
   * @param {*} data
   * @returns
   * @memberof SendnotificationinvoiceComponent
   */
  add_zero_date(data) {
    return data < 10 ? "0" + data.toString() : data;
  }

  //SWAL PARA MOSTRAR ERRORES O VALIDACIONES DE ENVIO DE DATOS A LOS SERVICIOS
  fun_load_swal(title, text, type) {
    swal(
      {
        title: title,
        text: text,
        type: type,
        showCancelButton: false,
        confirmButtonColor: "#8edbff",
        confirmButtonText: "Aceptar",
        //cancelButtonText: 'Cancelar',
        closeOnConfirm: true,
        closeOnCancel: true,
      },
      (isConfirm) => {}
    );
  }
}
