import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NewsService } from '../../services/notification/news.service';
import { User } from '../../routes/administrate/class/class.user';
import {TokenService} from '../../models/services/token.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit{

  public usersession;
  public numadmin = 0;
  public numrepresentantes = 0;
  @Output() public total = new EventEmitter<number>();
  @Input() public openNoveltiesModal = new EventEmitter<boolean>();
  selectedItem: number = 0;
  constructor(
    private newsservice: NewsService,
    private token: TokenService
  ) { }

  changeItem(event) {
    this.selectedItem = event;
  }

  ngOnInit() {
    // console.log('news component init ');
    this.usersession = this.token.USER; }

  getcountrepresentantes(event) {
    this.numrepresentantes = event;
    let totalaux = this.numadmin + this.numrepresentantes;
    this.total.emit(totalaux);

  }

  getcountadmin(event) {
    this.numadmin = event;
    let totalaux = this.numadmin + this.numrepresentantes;
    this.total.emit(totalaux);
  }

  getcountsuperadmin(event) {
    this.numadmin = event;
    let totalaux = this.numadmin;
    this.total.emit(totalaux);
  }

}
